/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { React, useEffect, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import { ReactComponent as Note } from '../../../assets/components/Sidebar/Notes.svg';
import { ReactComponent as Survey } from '../../../assets/components/Sidebar/painSurvey.svg';
import { ReactComponent as Exercise } from '../../../assets/components/Sidebar/exercise.svg';
import { ReactComponent as Dropdown } from '../../../assets/components/Sidebar/dropdownEx.svg';
import PatientNotes from '../../popups/general/PatientNotes.Popup';
import request from '../../../request/index';
import PatientSidebarSection from './PatientSidebarSection';

export default function Sidebar({
  session,
  patient,
  setActiveTab,
  activeTab,
  handleGetExerciseById,
}) {
  const [expanded, setExpanded] = useState(true);
  const [patientNotes, setPatientNotes] = useState();
  const [toggleIcon, setToggleIcon] = useState(false);
  const [notesActive, setNotesActive] = useState(false);
  const [activeItem, setActiveItem] = useState('');
  const [patientID, setPatientID] = useState('');

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setToggleIcon(!toggleIcon);
  };
  const handleTimeTracker = () => {
    if (patient?.id) {
      request('get', `/clinicians/notes/${patient?.id}`)
        .then((response) => {
          setPatientNotes(response?.data?.notes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    setPatientID(patient?.id);
    handleTimeTracker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.id]);
  const handleActiveTab = (targetTab) => {
    setActiveTab(targetTab);
  };
  const handleNotesClick = () => {
    setNotesActive(true);
  };

  useEffect(() => {
    if (session?.exercises) {
      setActiveItem(session?.exercises[0]?.name);
    }
  }, [session?.exercises]);

  return (
    <div className="left_side">
      <PatientSidebarSection patientData={patient} />
      <div className="activityLeft" role="presentation">
        <div className="image_div">
          <Exercise className={expanded ? 'iconActivity' : ''} />
        </div>
        <div
          className="dropdownIcons"
          onClick={() => {
            handleExpandClick();
          }}
        >
          <p className={expanded ? 'clickedActivity' : 'activityTxt'}>Exercise</p>
          {expanded ? (
            <Dropdown
              style={{
                cursor: 'pointer',
              }}
              className={expanded ? 'divider' : ''}
            />
          ) : (
            <Dropdown
              style={{
                transform: 'rotateX(180deg)',
                cursor: 'pointer',
              }}
              className={expanded ? 'divider' : ''}
            />
          )}
        </div>
      </div>
      <div className="listDiv">
        <Collapse in={expanded} timeout="auto">
          <div
            className="exerciseList"
            style={{
              borderLeft: '2px solid #136773',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <div>
              {session?.exercises?.map((exercise) => (
                <div
                  key={exercise?.id}
                  className={exercise.name === activeItem ? 'selectedItemExercise' : 'itemExercise'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleGetExerciseById(exercise?.id);
                    handleActiveTab('exercise');
                    setActiveItem(exercise?.name);
                  }}
                >
                  {exercise?.name ?? ''}
                </div>
              ))}
            </div>
          </div>
        </Collapse>
      </div>
      <div
        onClick={() => handleActiveTab('Pain Survey')}
        className={
          activeTab === 'Pain Survey' && !notesActive ? 'selectedExActivity' : 'activityEx'
        }
      >
        <div className="image_div">
          <Survey className="exIcon" />
        </div>
        <p className="activityTxt">Pain Survey</p>
      </div>
      {/* <div
        onClick={() => handleActiveTab('Reports')}
        className={activeTab === 'Reports' && !notesActive ? 'selectedExActivity' : 'activityEx'}
      >
        <div className="image_div">
          <Msg className="exIcon" />
        </div>
        <p className="activityTxt">Reports</p>
      </div> */}

      <div onClick={handleNotesClick} className={notesActive ? 'selectedExActivity' : 'activityEx'}>
        <div className="image_div">
          <Note className="exIcon" />
        </div>
        <p className="activityTxt">Notes</p>
      </div>
      {notesActive && (
        <PatientNotes
          openNotes={notesActive}
          setOpenNotes={setNotesActive}
          NotesData={patientNotes}
          ClinicianId={session?.clinician}
          PatientID={patientID}
          handleTimeTracker={handleTimeTracker}
        />
      )}
    </div>
  );
}
