// multi select component
import { useState, useEffect } from 'react';
import AvatarComponent from '../Avatar/Avatar.Component';

export default function ReactSelect({ dataOptions, setUserData, userData }) {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [preSelected] = useState(
    userData?.clinicians?.map((doctor) => ({
      id: doctor?.id,
      optName: `${doctor?.first_name} ${doctor?.last_name}`,
    })) ?? [],
  );

  useEffect(() => {
    setOptions(dataOptions);
    setSelected(preSelected);
  }, [dataOptions, preSelected]);

  useEffect(() => {
    const clinicians = selected.map((item) => item.id);
    setUserData((prevState) => ({ ...prevState, clinicians }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSelect = (opt) => {
    setSelected([...selected, opt]);
    setOptions((prev) => {
      return prev.filter((_) => _.id !== opt.id);
    });
  };

  const handleUnselect = (opt) => {
    if (selected.length > 1) {
      setSelected((prev) => {
        return prev.filter((_) => _.id !== opt.id);
      });
      setOptions([...options, opt]);
    }
  };

  return (
    <div>
      <div className="customSelect" style={{ position: 'relative' }}>
        {/* {preSelected?.map((opt) => (
          <div className="customOptions" role="presentation">
            <AvatarComponent alt={opt?.optName} className="opt_avatar" />
            <p className="optName">{opt?.optName}</p>
          </div>
        ))} */}
        {selected?.length > 0
          ? selected?.map((opt) => (
              <div
                key={opt}
                className="customOptions"
                role="presentation"
                onClick={() => handleUnselect(opt)}
              >
                <AvatarComponent alt={opt?.optName} className="opt_avatar" />
                <p className="optName">{opt?.optName}</p>
              </div>
            ))
          : !selected.length && 'Select Clinician'}
        {selected?.length < 2 && (
          <span style={{ position: 'absolute', bottom: 0, fontSize: '9px' }}>
            There should be at least 1 clinician.
          </span>
        )}
      </div>
      <div className="OptNames">
        {options?.length > 0 ? (
          options?.map((opt) => (
            <div
              className="customOptions"
              role="presentation"
              onClick={() => handleSelect(opt)}
              key={opt}
            >
              <AvatarComponent alt={opt?.optName} className="opt_avatar" />
              <p className="optName">{opt?.optName}</p>
            </div>
          ))
        ) : (
          <p className="notxt">No Clinician found</p>
        )}
      </div>
    </div>
  );
}
