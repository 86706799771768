import { React, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { FormControl, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import DatePicker from 'react-datepicker';
import { getMediaUrl } from '../../../config';
import 'react-datepicker/dist/react-datepicker.css';
import edit from '../../../assets/icons/edit.svg';
import AvatarComponent from '../../Avatar/Avatar.Component';
import emailValidation from '../../../utils/emailValidation';
import request from '../../../request';

const useOutlinedInputStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #454238',
        borderRadius: '20px',
        fontSize: 16,
        height: 55,
      },
      '&:hover fieldset': {
        borderColor: '#FBA622',
      },
      '&::placeholder fieldset': {
        fontWeight: 800,
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FBA622',
      },
    },
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-root': {
    borderRadius: '20px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(7),
    textAlign: 'center',
  },
  '& .MuiDialogActions-root': {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(7),
    margin: 'auto',
  },
}));

export default function EditStaffMember({
  openEditStaffMember,
  setOpenEditStaffMember,
  getStaffMembers,
  staffMemberDetails,
  getData,
}) {
  const [checked, setChecked] = useState(false);
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  const [loading, setLoading] = useState(false);
  const [staffMember, setStaffMember] = useState({});
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const futureYear = new Date();
  futureYear.setFullYear(futureYear.getFullYear() + 10);

  useEffect(() => {
    const tempStaffMember = {
      first_name: staffMemberDetails?.first_name,
      last_name: staffMemberDetails?.last_name,
      email: staffMemberDetails?.email,
      profile_pic: staffMemberDetails?.profile_pic,
      phone_number: staffMemberDetails?.phone_number,
    };

    if (staffMemberDetails?.expiry_date) {
      // eslint-disable-next-line no-underscore-dangle
      tempStaffMember.expiry_date = moment(staffMemberDetails.expiry_date)._d;
      setChecked(true);
    }

    setStaffMember(tempStaffMember);
  }, [staffMemberDetails]);

  const outlinedInputStyles = useOutlinedInputStyles();

  const handleClose = () => {
    setOpenEditStaffMember(false);
  };

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleChange = (e) => {
    setStaffMember({ ...staffMember, [e.target.name]: e.target.value });
  };

  const dateChange = (e) => {
    setStaffMember({
      ...staffMember,
      expiry_date: e,
    });
  };

  const handleSelectedFile = (e) => {
    const myFile = e.target.files[0];
    const size = 1024 * 1024 * 10;
    if (myFile.size > size) {
      return toast.error('File size can not exceed 10 Mb');
    }
    setStaffMember({ ...staffMember, profile_pic: myFile });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const staffMemberCopy = { ...staffMember };
    const formData = new FormData();
    if (staffMemberCopy.expiry_date && !checked) {
      staffMemberCopy.expiry_date = '';
      setStaffMember({ ...staffMemberCopy });
    }

    if (typeof staffMemberCopy.profile_pic !== 'object') {
      delete staffMemberCopy.profile_pic;
    }

    Object.keys(staffMemberCopy).map((key) => formData.append(key, staffMemberCopy[key]));
    if (staffMemberCopy.phone_number && staffMemberCopy.phone_number.length < 11) {
      return toast.error('Phone number must contain 10 digits');
    }

    if (checked && !staffMemberCopy.expiry_date) {
      return toast.error('Please enter expiration date');
    }
    // this function will return true if email validation fails (for production only)
    if (emailValidation(staffMemberCopy.email)) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await request('put', `/admins/editStaff/${staffMemberDetails.id}`, formData);
      if (data) {
        setLoading(false);
        handleClose();
        toast.success('Staff Member edited successfully');
        setOpenEditStaffMember(false);
        getData();
        getStaffMembers();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };
  const handleDeleteUser = async (targetUserId) => {
    await request('delete', `/admins/deleteStaff/${targetUserId}`)
      .then(() => {
        toast.success('Staff Member Deactivated successfully');
        handleClose();
        setOpenEditStaffMember(false);
        getData();
        getStaffMembers();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openEditStaffMember}
        setOpen={setOpenEditStaffMember}
        fullWidth
        width="40.8%"
        minWidth="90%"
        PaperProps={{
          style: { borderRadius: 20, background: '#FFFFFF' },
        }}
      >
        <DialogContent>
          <div className="loginClearIconCopy">
            <ClearIcon onClick={handleClose} />
          </div>
          <div>
            <Typography gutterBottom>
              <h1 className="addPopupTxt">Edit Staff Member</h1>
              <p className="addPopupSubTxt"> </p>
              <div>
                <div className="imgDivPopup">
                  <label htmlFor="edit_profilePic">
                    <input
                      name="profile_pic"
                      type="file"
                      id="edit_profilePic"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => {
                        handleSelectedFile(e);
                      }}
                      className="noDisplay"
                    />

                    {staffMember?.profile_pic ? (
                      <img
                        src={getMediaUrl(staffMember.profile_pic)}
                        className="pointer"
                        id="selectedImg"
                        style={{
                          width: '100%',
                          borderRadius: '100px',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        alt=""
                      />
                    ) : (
                      <AvatarComponent
                        alt={`${staffMember?.first_name} ${staffMember?.last_name}`}
                        className="popups_avatar"
                      />
                    )}
                    <img src={edit} className="addImgIcon" alt="" />
                  </label>
                </div>
              </div>
              <FormControl sx={{ mb: 1, mt: 3, width: '48.5%', mr: 1.5 }} variant="outlined">
                <TextField
                  sx={{
                    borderRadius: '20px',
                    input: {
                      color: 'black',
                      fontSize: '14px',
                      fontWeight: '500',
                    },
                  }}
                  id="outlined-fname"
                  classes={outlinedInputStyles}
                  placeholder="First Name"
                  size="normal"
                  name="first_name"
                  value={staffMember?.first_name}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              </FormControl>
              <FormControl sx={{ mb: 1, mt: 3, width: '48.5%' }} variant="outlined">
                <TextField
                  sx={{
                    borderRadius: '20px',
                    input: {
                      color: 'black',
                      fontSize: '14px',
                      fontWeight: '500',
                    },
                  }}
                  id="outlined-fname"
                  classes={outlinedInputStyles}
                  placeholder="Last Name"
                  size="normal"
                  name="last_name"
                  onChange={handleChange}
                  value={staffMember?.last_name}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              </FormControl>
              <FormControl sx={{ mb: 2, mt: 1, width: '48.5%', mr: 1.5 }} variant="outlined">
                <TextField
                  sx={{
                    borderRadius: '20px',
                    input: {
                      color: 'black',
                      fontSize: '14px',
                      fontWeight: '500',
                    },
                  }}
                  id="outlined-email"
                  classes={outlinedInputStyles}
                  placeholder="Email"
                  size="normal"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={staffMember?.email}
                />
              </FormControl>
              <FormControl sx={{ mb: 1, mt: 1, width: '48.5%' }} variant="outlined">
                <div className="edit-phone-input">
                  <PhoneInput
                    country="us"
                    onlyCountries={['us']}
                    value={staffMember.phone_number}
                    onChange={(value) => {
                      setStaffMember({ ...staffMember, phone_number: value });
                    }}
                    disableDropdown
                    countryCodeEditable={false}
                    disableCountryGuess
                  />
                </div>
              </FormControl>
              <FormControl
                sx={{
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                <FormControlLabel
                  sx={{
                    color: '#136773',
                    textAlign: 'left',
                    fontSize: '40px',
                    width: 'fit-content',
                  }}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheck}
                      style={{ color: '#136773' }}
                    />
                  }
                  label={
                    <Typography
                      style={{
                        color: '#136773',
                        textAlign: 'left',
                        fontWeight: '500',
                      }}
                    >
                      Want to Add expiration date
                    </Typography>
                  }
                />
              </FormControl>
              <FormControl
                sx={{
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                <div className="date-div2" id={!checked ? 'disabledDate' : ''}>
                  <DatePicker
                    dateFormat="MM-dd-yyyy"
                    name="expiry_date"
                    onChange={dateChange}
                    disabled={!checked}
                    selected={staffMember?.expiry_date}
                    placeholderText="Enter expiration date"
                    popperPlacement="bottom-start"
                    showYearDropdown
                    disablePast
                    minDate={minDate}
                    scrollableYearDropdown
                    maxDate={futureYear}
                    yearDropdownItemNumber={10}
                  />
                </div>
              </FormControl>
            </Typography>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            width: '100%',
          }}
        >
          <Button
            className="deactivateUserBtn"
            variant="contained"
            onClick={() => {
              handleDeleteUser(staffMemberDetails?.id);
            }}
          >
            Deactivate User
          </Button>
          <Button
            onClick={handleSubmit}
            className={loading ? 'resetPasswordBtnloading' : 'resetPasswordBtn'}
            variant="contained"
            disabled={loading}
          >
            {loading && (
              <div style={{ height: 'inherit' }} className="progressContainer">
                <CircularProgress className="whiteCircularProgress" size={20} />
              </div>
            )}
            Save Changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
