import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function MenuBtn({ handleDeleteFromList, row, index, setOpenPopup, setTargetExercise }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleEditBtn = (targetRow, targetIndex) => {
    setOpenPopup(true);
    handleClose();
    setTargetExercise({
      selectExercise: targetRow,
      exerciseIndex: targetIndex,
    });
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`long-menu-${index}`}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '18ch',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleEditBtn(row, index);
          }}
          className="grayColorClass"
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteFromList(index);
            handleClose();
          }}
          className="grayColorClass"
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}

export default MenuBtn;
