/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
// import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import { ReactComponent as Action } from '../../../assets/components/Table/dots.svg';
import { ReactComponent as OpenArrow } from '../../../assets/components/Sidebar/openArrow.svg';
// import { ReactComponent as Complete } from '../../../assets/components/Table/check.svg';
// import { ReactComponent as Pending } from '../../../assets/components/Table/uncheck.svg';
// import { ReactComponent as Error } from '../../../assets/components/Table/Error.svg';
// import { ReactComponent as Inprogress } from '../../../assets/components/Table/inprogress.svg';
// import { ReactComponent as Incomplete } from '../../../assets/components/Table/Incomplete.svg';
import { ReactComponent as Edit } from '../../../assets/icons/editIcon.svg';
import { ReactComponent as Tick } from '../../../assets/components/Table/markTick.svg';
import { ReactComponent as SimpleTick } from '../../../assets/components/Table/VectorTickComplete.svg';
import { ReactComponent as SimpleCross } from '../../../assets/components/Table/VectorErrored.svg';

import AvatarComponent from '../../Avatar/Avatar.Component';
import EditPrescription from '../../popups/prescription/EditPrescription.Popup';
import request from '../../../request';
// import PieChartComponent from '../../charts/patient/PieChart.Component';
import TechoChart from '../../charts/patient/TechometerChart';
import { timezoneOffset } from '../../../config';

export default function PrescriptionTable({ patientId, selectedClinicianId }) {
  const navigate = useNavigate();
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [isOpen, setIsOpen] = useState({});
  const [openPrescriptionMenu, setOpenPrescriptionMenu] = useState(false);
  const [openEditPrescription, setOpenEditPrescription] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, item) => {
    setOpenPrescriptionMenu(!openPrescriptionMenu);
    setSelectedPrescription(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSelectedPrescription({});
    setAnchorEl(null);
    setOpenPrescriptionMenu(false);
  };
  const handleCloseEdit = () => {
    setAnchorEl(null);
    setOpenEditPrescription(true);
    setOpenPrescriptionMenu(false);
  };
  const handlePrescriptionList = async () => {
    setLoading(true);
    await request(
      'get',
      `/clinicians/prescriptions/getPrescriptionListByPatientIdAndClinicianId/${patientId}/${selectedClinicianId}`,
    )
      .then((res) => {
        setLoading(false);
        setPrescriptionList(res?.data?.prescriptions);
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error?.response?.data?.message);
        // toast.error(error?.response?.data?.message);
      });
  };
  const handleCloseCompleted = async (prescriptionId) => {
    setLoading(true);
    await request('put', `/clinicians/prescriptions/completePrescription/${prescriptionId}`)
      .then(() => {
        setLoading(false);
        setAnchorEl(null);
        setOpenPrescriptionMenu(false);
        handlePrescriptionList();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  // const handleUpdateSessionStatus = (targetSessionId) => {
  //   request('get', `/clinicians/sessions/addSessionStatus/${targetSessionId}`)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const percentageOfGraph = (targetRow) => {
    // eslint-disable-next-line no-unused-expressions
    if (targetRow > 0 && targetRow <= 10) {
      return '#FF5151';
    }
    if (targetRow > 10 && targetRow <= 30) {
      return '#C2623B';
    }
    if (targetRow > 30 && targetRow <= 40) {
      return '#D0A34D';
    }
    if (targetRow > 40 && targetRow <= 50) {
      return '#E7D270';
    }
    if (targetRow > 50 && targetRow <= 55) {
      return '#F0E570';
    }
    if (targetRow > 55 && targetRow <= 65) {
      return '#E0EE66';
    }
    if (targetRow > 65 && targetRow <= 70) {
      return '#CAE662';
    }
    if (targetRow > 70 && targetRow <= 75) {
      return '#A9D55D';
    }
    if (targetRow > 75 && targetRow <= 80) {
      return '#AED05D';
    }
    if (targetRow > 80 && targetRow <= 85) {
      return '#97C755';
    }
    if (targetRow > 85 && targetRow <= 90) {
      return '#7EB252';
    }
    if (targetRow > 90 && targetRow <= 95) {
      return '#7EB45A';
    }
    return '#669851';
  };

  const handleSearchLogoClick = (sessionId) => {
    navigate(`/patients/${sessionId}/exercise/exerciseDetails`);
  };
  const toggleOpen = (category) => {
    setIsOpen({
      ...isOpen,
      [category]: !isOpen[category],
    });
  };
  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#F6F6F6',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      borderBottom: '1px solid transparent',
    },
  }));
  // const RedLinearProgress = styled(LinearProgress)(({ theme }) => ({
  //   height: 6,
  //   borderRadius: 5,
  //   [`&.${linearProgressClasses.colorPrimary}`]: {
  //     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  //   },
  //   [`& .${linearProgressClasses.bar}`]: {
  //     borderRadius: 5,
  //     backgroundColor: '#FF5151',
  //   },
  // }));
  // const YellowLinearProgress = styled(LinearProgress)(({ theme }) => ({
  //   height: 6,
  //   borderRadius: 5,
  //   [`&.${linearProgressClasses.colorPrimary}`]: {
  //     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  //   },
  //   [`& .${linearProgressClasses.bar}`]: {
  //     borderRadius: 5,
  //     backgroundColor: '#FABB18',
  //   },
  // }));
  // const GreenLinearProgress = styled(LinearProgress)(({ theme }) => ({
  //   height: 6,
  //   borderRadius: 5,
  //   [`&.${linearProgressClasses.colorPrimary}`]: {
  //     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  //   },
  //   [`& .${linearProgressClasses.bar}`]: {
  //     borderRadius: 5,
  //     backgroundColor: '#25CC7C',
  //   },
  // }));
  // const percentageOfGraph = (row) => {
  //   // eslint-disable-next-line no-unused-expressions
  //   if (row?.avg_score > 0 && row?.avg_score <= 10) {
  //     return '#FF5151';
  //   }
  //   if (row?.avg_score > 10 && row?.avg_score <= 30) {
  //     return '#C2623B';
  //   }
  //   if (row?.avg_score > 30 && row?.avg_score <= 40) {
  //     return '#D0A34D';
  //   }
  //   if (row?.avg_score > 40 && row?.avg_score <= 50) {
  //     return '#E7D270';
  //   }
  //   if (row?.avg_score > 50 && row?.avg_score <= 55) {
  //     return '#F0E570';
  //   }
  //   if (row?.avg_score > 55 && row?.avg_score <= 65) {
  //     return '#E0EE66';
  //   }
  //   if (row?.avg_score > 65 && row?.avg_score <= 70) {
  //     return '#CAE662';
  //   }
  //   if (row?.avg_score > 70 && row?.avg_score <= 75) {
  //     return '#A9D55D';
  //   }
  //   if (row?.avg_score > 75 && row?.avg_score <= 80) {
  //     return '#AED05D';
  //   }
  //   if (row?.avg_score > 80 && row?.avg_score <= 85) {
  //     return '#97C755';
  //   }
  //   if (row?.avg_score > 85 && row?.avg_score <= 90) {
  //     return '#7EB252';
  //   }
  //   if (row?.avg_score > 90 && row?.avg_score <= 95) {
  //     return '#7EB45A';
  //   }
  //   return '#669851';
  // };

  useEffect(() => {
    handlePrescriptionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, selectedClinicianId]);

  // const sessionStatus = {
  //   pending: <Pending />,
  //   'in-progress': <Inprogress />,
  //   completed: <Complete />,
  //   errored: <Error />,
  //   unchecked: <Incomplete />,
  // };
  console.log(prescriptionList, 'prescriptionList');
  return (
    <div id="prescriptionTable">
      <h3 className="prescription_header">Prescription List</h3>
      {loading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ color: '#136773' }} />
        </div>
      ) : prescriptionList && prescriptionList.length > 0 ? (
        prescriptionList?.map((mainRow, index) => (
          <TableContainer
            key={mainRow?.id}
            component={Paper}
            sx={{
              borderRadius: '20px',
              maxHeight: '84%',
              overflowX: 'hidden',
              width: '76vw',
              boxShadow: '0',
              margin: 'auto',
              marginBottom: '25px',
              '@media only screen and (max-width: 1400px)': {
                maxWidth: '73vw',
                overflowX: 'auto',
              },
            }}
          >
            <Table
              aria-label="simple table"
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                  verticalAlign: 'baseline',
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    size="small"
                    className="prescription_head_title vertical_middle"
                    sx={{
                      minWidth: '8rem',
                      maxWidth: '8rem',
                      textTransform: 'capitalize',
                    }}
                  >
                    {mainRow?.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    className="table_header"
                    sx={{
                      minWidth: '8rem',
                      maxWidth: '8rem',
                      textAlign: 'center',
                    }}
                  >
                    <div className="exerciseItems">
                      <p className="excerciseItemHeading">Exercise</p>
                      {mainRow?.exercise_names?.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    className="table_header"
                    style={{ maxWidth: '8rem' }}
                  >
                    <div>
                      <p>Duration</p>
                      <p className="prescription_head">
                        {mainRow?.duration} {mainRow?.duration === 1 ? 'week' : 'weeks'}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell align="left" size="small" className="table_header">
                    <div>
                      <p>Frequency</p>
                      <p className="prescription_head">
                        {mainRow?.frequency} {mainRow?.frequency === 1 ? 'Time' : 'Times'}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{ minWidth: '8rem', maxWidth: '8rem' }}
                    align="left"
                    size="small"
                    className="table_header"
                  >
                    <div>
                      <p>Assign by</p>
                      <div style={{ display: 'flex', marginTop: '7px', alignItems: 'center' }}>
                        <AvatarComponent
                          alt={`${mainRow?.clinician?.first_name} ${mainRow?.clinician?.last_name}`}
                          className="header_avatar"
                        />
                        <div className="prescription_cell">
                          {mainRow?.clinician?.first_name} {mainRow?.clinician?.last_name}
                          <br />
                          <span className="presSubtxt">{mainRow?.clinician?.role}</span>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="left" size="small" className="table_header">
                    <div>
                      <p>Date</p>
                      <p className="prescription_head">
                        {moment(mainRow?.start_date)
                          .utc()
                          .utcOffset(timezoneOffset)
                          .format('MM/DD/YYYY')}{' '}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {mainRow.status !== 'completed' ? (
                      <div>
                        <Action
                          className="pointer"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(e) => {
                            handleClick(e, mainRow);
                          }}
                        />
                        {openPrescriptionMenu && selectedPrescription.id === mainRow.id ? (
                          <Menu
                            defaultValue={0}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                            PaperProps={{
                              style: {
                                backgroundColor: '#F6FAFB',
                                boxShadow: '1',
                                border: '1px ',
                                borderColor: 'black',
                                borderRadius: '15px',
                              },
                            }}
                          >
                            <MenuItem
                              className="editMenuItem"
                              role="presentation"
                              onClick={() => {
                                handleCloseEdit();
                              }}
                            >
                              <Edit
                                className="pIcon"
                                style={{
                                  filter:
                                    'brightness(0) saturate(100%) invert(66%) sepia(69%) saturate(1538%) hue-rotate(344deg) brightness(107%) contrast(97%)',
                                }}
                              />
                              <p className="actionMenus">Edit Prescription</p>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleCloseCompleted(mainRow?.id);
                              }}
                            >
                              <Tick className="pIcon" />
                              <p className="actionMenus">Mark as Completed</p>
                            </MenuItem>
                          </Menu>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <>
                        <p className="table_header" size="small">
                          Status
                        </p>
                        <Tick className="pIcon" style={{ marginTop: '10px' }} />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell align="left" />
                  <TableCell align="left" />
                  <TableCell align="left" />
                  <TableCell align="center">
                    {(mainRow?.status !== 'pending' || mainRow?.status !== 'in-progress') &&
                      mainRow.sessions.length > 0 && (
                        <OpenArrow
                          onClick={() => {
                            toggleOpen(index);
                          }}
                          style={{
                            cursor: 'pointer',
                            transform: !isOpen[index] && 'rotateX(180deg)',
                          }}
                        />
                      )}
                  </TableCell>
                  <TableCell align="left" />
                  <TableCell align="left" />
                </TableRow>
              </TableHead>
              {isOpen[index] && (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size="small" className="table_header">
                        Date
                      </TableCell>
                      <TableCell align="center" size="small" className="table_header">
                        {/* Performance */}
                        {/* {mainRow?.sessions?.[0]?.type === 'simple' ? 'Pain Before' : 'Performance'} */}
                        Pain Before
                      </TableCell>
                      <TableCell align="center" size="small" className="table_header">
                        {/* Survey */}
                        {/* {mainRow?.sessions?.[0]?.type === 'simple' ? 'Pain After' : 'Survey'} */}
                        Pain After
                      </TableCell>

                      {mainRow?.sessions?.[0]?.type !== 'simple' && (
                        <TableCell align="center" size="small" className="table_header">
                          {/* Pain Scale */}
                          {mainRow?.sessions?.[0]?.type === 'simple' ? '' : 'Average Performance'}
                        </TableCell>
                      )}

                      <TableCell align="center" size="small" className="table_header">
                        {/* Pain Scale */}
                        {mainRow?.sessions?.[0]?.type === 'simple' ? 'Difficulty' : ''}
                      </TableCell>

                      <TableCell />
                      {/* <TableCell align="center" size="small" className="table_header">
                        Status
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mainRow?.sessions?.map((row) => (
                      <StyledTableRow
                        className="tableRow"
                        key={row?.id}
                        onClick={() => {
                          handleSearchLogoClick(row?.id);
                          // handleUpdateSessionStatus(row?.id);
                        }}
                      >
                        <TableCell align="center" className="prescription_head vertical_middle">
                          {moment(
                            `${new Date(row?.date)?.toISOString()?.split('T')[0]}T${
                              new Date(mainRow?.start_date)?.toISOString()?.split('T')[1]
                            }`,
                          )
                            .utc()
                            .utcOffset(timezoneOffset)
                            .format('MM/DD/YYYY')}
                        </TableCell>
                        <TableCell
                          align="center"
                          id="technometerVal3Rel"
                          className="prescription_head vertical_middle"
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {/* <TechoChart value={(row?.survey && row?.survey?.[0]?.answer) / 10} /> */}

                            {row && row?.survey && !Number.isNaN(row?.survey?.[0]?.answer) ? (
                              <TechoChart value={row?.survey?.[0]?.answer / 10} />
                            ) : (
                              '-'
                            )}
                            {/* <p className="technometerVal3">{row?.survey?.[0]?.answer}</p> */}

                            {/* <p className="technometerVal3">
                              {Number.isNaN(row?.survey?.[0]?.answer)
                                ? 0
                                : Math.round(parseFloat(row?.survey?.[0]?.answer) * 100) / 100}
                            </p> */}
                            <p className="technometerVal3">
                              {row &&
                              row.survey &&
                              row.survey[0] &&
                              !Number.isNaN(row.survey[0].answer)
                                ? Math.round(parseFloat(row.survey[0].answer) * 100) / 100
                                : ''}
                            </p>
                          </div>

                          {/* {row?.avg_performance && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                              }}
                            >
                              <CircularProgress
                                variant="determinate"
                                thickness="6"
                                value={row?.avg_performance}
                                size={60}
                                sx={{
                                  color: percentageOfGraph(row),
                                }}
                              />
                              <div
                                style={{
                                  position: 'absolute',
                                  right: '50%',
                                  bottom: '50%',
                                  transform: 'translate(50%,50%)',
                                  fontSize: '15px',
                                }}
                              >
                                {Math.round(row?.avg_performance)}%
                              </div>
                            </div>
                          )} */}

                          {/* <span className="linearPresProgress">{`${row?.avg_score ?? 0}%`}</span>
                          {row?.avg_score <= 30 ? (
                            <RedLinearProgress
                              variant="determinate"
                              value={row?.avg_score}
                              thickness="40"
                              sx={{ width: '80%' }}
                            />
                          ) : row?.avg_score > 30 && row?.avg_score <= 79 ? (
                            <YellowLinearProgress
                              variant="determinate"
                              value={row?.avg_score}
                              thickness="40"
                              sx={{ width: '80%' }}
                            />
                          ) : row?.avg_score > 79 ? (
                            <GreenLinearProgress
                              variant="determinate"
                              value={row?.avg_score}
                              thickness="40"
                              sx={{ width: '80%' }}
                            />
                          ) : (
                            ''
                          )} */}
                        </TableCell>
                        <TableCell
                          align="center"
                          id="technometerValRel"
                          className="prescription_head vertical_middle"
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {/* <TechoChart value={(row?.survey && row?.survey?.[1]?.answer) / 10} /> */}

                            {row && row?.survey && !Number.isNaN(row?.survey?.[1]?.answer) ? (
                              <TechoChart value={row?.survey?.[1]?.answer / 10} />
                            ) : (
                              '-'
                            )}
                            {/* <p className="technometerVal">{row?.survey?.[1]?.answer}</p> */}

                            {/* <p className="technometerVal">
                              {Number.isNaN(row?.survey?.[1]?.answer)
                                ? 0
                                : Math.round(parseFloat(row?.survey?.[1]?.answer) * 100) / 100}
                            </p> */}
                            <p className="technometerVal">
                              {row &&
                              row.survey &&
                              row.survey[1] &&
                              !Number.isNaN(row.survey[1].answer)
                                ? Math.round(parseFloat(row.survey[1].answer) * 100) / 100
                                : ''}
                            </p>
                          </div>

                          {/* {row?.type !== 'simple' && (
                            <p> {row?.survey?.length > 0 ? 'Done' : 'Not Done'}</p>
                          )} */}
                        </TableCell>
                        {row?.type !== 'simple' && (
                          <TableCell
                            align="center"
                            className="prescription_head vertical_middle"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {row?.type !== 'simple' && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  position: 'relative',
                                }}
                                id="technometerVal2Rel"
                              >
                                <CircularProgress
                                  variant="determinate"
                                  thickness="6"
                                  value={row?.avg_performance}
                                  size={45}
                                  sx={{
                                    color: percentageOfGraph(row?.avg_performance),
                                  }}
                                />
                                <p style={{ position: 'absolute' }}>
                                  {(row?.avg_performance || 0).toFixed(0)}%
                                </p>
                              </div>
                            )}
                          </TableCell>
                        )}

                        <TableCell align="center" className="prescription_head vertical_middle">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            id="technometerVal2Rel"
                          >
                            {row?.type === 'simple' && (
                              <>
                                {row && row?.survey && !Number.isNaN(row?.survey?.[2]?.answer) ? (
                                  <TechoChart value={row?.survey?.[2]?.answer / 10} />
                                ) : (
                                  '-'
                                )}
                                <p className="technometerVal2">
                                  {row &&
                                  row.survey &&
                                  row.survey[2] &&
                                  !Number.isNaN(row.survey[2].answer)
                                    ? Math.round(parseFloat(row.survey[2].answer) * 100) / 100
                                    : ''}
                                </p>
                              </>
                            )}
                          </div>
                        </TableCell>
                        <TableCell className="prescription_head vertical_middle" align="center">
                          {/* {sessionStatus[row.status]} */}

                          {row?.type === 'simple' && row?.status === 'completed' ? (
                            <SimpleTick />
                          ) : row?.type === 'simple' && row?.status !== 'completed' ? (
                            <SimpleCross />
                          ) : null}
                        </TableCell>

                        <TableCell />
                        <TableCell className="prescription_head vertical_middle" align="center">
                          {/* {sessionStatus[row.status]} */}
                        </TableCell>

                        <TableCell className="prescription_head vertical_middle" align="center" />
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </>
              )}
            </Table>
            {openEditPrescription && (
              <EditPrescription
                openEditPrescription={openEditPrescription}
                setOpenEditPrescription={setOpenEditPrescription}
                selectedPrescription={selectedPrescription}
                handlePrescriptionList={handlePrescriptionList}
              />
            )}
          </TableContainer>
        ))
      ) : (
        <h1 className="NoPrescriptionTxt">No Prescription Found</h1>
      )}
    </div>
  );
}
