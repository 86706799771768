import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { ReactComponent as YellowCircle } from '../../assets/components/patientDetailCards/yellowCircle.svg';
import { ReactComponent as ActivePatients } from '../../assets/components/patientDetailCards/activePatients.svg';
import { ReactComponent as GreenIcon } from '../../assets/components/StatsCard/greenIcon.svg';
import GreenStats from '../../assets/components/StatsCard/greenStats.svg';
import { ReactComponent as YellowIcon } from '../../assets/components/StatsCard/yellowIcon.svg';
import YellowStats from '../../assets/components/StatsCard/yellowStats.svg';
import { ReactComponent as GrayIcon } from '../../assets/components/StatsCard/grayIcon.svg';
import GrayStats from '../../assets/components/StatsCard/grayStats.svg';
import request from '../../request';
import PatientsTable from '../../components/tables/clinician/PatientsByCliniciansTable';
import StatsCard from '../../components/cards/StatsCard.Component';
import AddNewPatient from '../../components/popups/patient/AddPatient.Popup';
import { Store } from '../../StoreContext';
import { timeoutDuration, eventsToTrack } from '../../utils/allEvents';

export default function ClinicianDashboard() {
  const [openAddPatient, setOpenAddPatient] = useState(false);
  const [patientsByClinician, setPatientsByClinician] = useState({});
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [page, setPage] = useState(0);
  const { user, socket } = Store();
  const location = useLocation();

  let isActive = true;

  const handleChangeCheck = () => {
    setChecked(!checked);
  };
  const getPatients = async () => {
    try {
      setLoading(true);

      const { data } = await request(
        'get',
        `/clinicians/dashboard/?page=${page}&&active=${checked}`,
      );
      if (data) {
        setPatientsByClinician(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleChangePage = async (event, newPage) => {
    await getPatients(newPage + 1);
    setPage(newPage);
  };
  const activateUser = (userId) => {
    request('patch', `/clinicians/activatePatient/${userId}`)
      .then(() => {
        getPatients();
      })
      .catch((err) => {
        console.log('~ file: Dashboard.Page.jsx:61 ~ activateUser ~ err:', err);
      });
  };
  const cardsData = [
    {
      icon: GreenIcon,
      amount: patientsByClinician?.count?.totalSession?.count || 0,
      title: 'Total no.  Exercises',
      icon2: GreenStats,
    },
    {
      icon: YellowIcon,
      amount: patientsByClinician?.count?.uncheckedSession?.count || 0,
      title: 'Unchecked Exercises',
      icon2: YellowStats,
    },
    {
      icon: GrayIcon,
      amount: patientsByClinician?.count?.completedSession?.count || 0,
      title: 'Completed Exercises',
      icon2: GrayStats,
    },
  ];

  const addNewPatientPopup = openAddPatient && (
    <AddNewPatient
      getPatients={getPatients}
      openAddPatient={openAddPatient}
      setOpenAddPatient={setOpenAddPatient}
    />
  );

  const handleInactiveEvent = () => {
    isActive = true;
    if (patientsByClinician?.users?.length) {
      socket.emit('activity:stop', {
        clinician_id: user?.id,
        patient_ids: patientsByClinician?.users?.map((item) => item.id),
        page_name: location?.pathname,
        start_time: Date.now(),
      });
    }
  };
  const resetTimeout = () => {
    clearTimeout(window.timeout);
    window.timeout = setTimeout(handleInactiveEvent, timeoutDuration);
  };
  const handleEvent = () => {
    if (isActive && patientsByClinician?.users?.length) {
      isActive = false;
      if (user && socket) {
        socket.emit('activity:start', {
          clinician_id: user?.id,
          patient_ids: patientsByClinician?.users?.map((item) => item.id),
          page_name: location?.pathname,
          start_time: Date.now(),
        });
      }
      // timeout starts as the user lands
      resetTimeout();
    } else {
      // after initialization only timeout is called
      resetTimeout();
    }
  };

  useEffect(() => {
    getPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  // first time emit start event
  useEffect(() => {
    // array is necessary to check so object doesn't go empty
    if (patientsByClinician?.users?.length && user.role === 'clinician') {
      handleEvent();
      eventsToTrack.forEach((eventName) => {
        window.addEventListener(eventName, handleEvent);
      });
    }

    return () => {
      eventsToTrack.forEach((eventName) => {
        window.removeEventListener(eventName, handleEvent);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientsByClinician?.users?.length]);

  return (
    <div className="clinician_dashboard_main_div">
      <div className="clinician_dashboard_left_div">
        <div className="vertical_patient_info_card_div">
          <div style={{ width: '100%' }} className="vertical_patient_info_card">
            <div style={{ marginLeft: '4%', display: 'flex', gap: '1rem' }}>
              <ActivePatients />
              <div className="active_patients_details_div">
                <span className="total_active_patients">
                  {patientsByClinician?.count?.activePatient?.count || 0}
                </span>
                <span className="active_patients_title">Total Patients</span>
              </div>
            </div>
            <YellowCircle className="stats_circle" style={{ color: 'rgba(250, 187, 24, 0.12)' }} />
          </div>
        </div>
        <PatientsTable
          loading={loading}
          setLoading={setLoading}
          getPatients={getPatients}
          patients={patientsByClinician}
          openAddPatient={openAddPatient}
          setOpenAddPatient={setOpenAddPatient}
          checked={checked}
          handleChangeCheck={handleChangeCheck}
          activateUser={activateUser}
          handleChangePage={handleChangePage}
          page={page}
        />
      </div>
      <div className="clinician_dashboard_right_div">
        <div className="clinician_statsCards_div">
          {cardsData?.map((item) => (
            <StatsCard
              key={item?.title}
              Icon={item?.icon}
              amount={item?.amount}
              title={item?.title}
              BottomStats={item?.icon2}
              statsCardClassName="stats_card_main_div2"
            />
          ))}
        </div>
      </div>
      {addNewPatientPopup}
    </div>
  );
}
