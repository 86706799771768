/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
import { React, useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Table, Button } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TablePagination from '@mui/material/TablePagination';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { ReactComponent as VisibilityOff } from '../../../assets/icons/eyess.svg';
import { ReactComponent as Filter } from '../../../assets/icons/filtersIcon.svg';
import { ReactComponent as Edit } from '../../../assets/components/Table/alert.svg';
import ExportReport from '../../popups/general/ExportReport.Popup';
import IconTextField from '../../CustomTextInput/CustomTextfield.Component';
import EditClinician from '../../popups/clinician/EditClinician.Popup';
import DeleteModal from '../../popups/general/Delete.Popup';
import { getMediaUrl } from '../../../config';
import AvatarComponent from '../../Avatar/Avatar.Component';
import PhoneFormat from '../../../utils/PhoneFormat';
import { Store } from '../../../StoreContext';

const useStyles = makeStyles(() => ({
  radio: {
    color: '#D1D1D6 !important',
    '&$checked': {
      color: '#136773 !important',
    },
  },
  checkBox: {
    color: ' #919B9B !important',
    '&$checked': {
      color: '#136773 !important',
    },
  },
  checked: {},
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    borderBottom: '1px solid transparent',
  },
}));

export default function AllCliniciansTable({
  clinicians,
  getClinicians,
  loading,
  checked,
  handleChangeCheck,
  activateUser,
}) {
  const { user } = Store();
  const [openEditClinician, setOpenEditClinician] = useState(false);
  const [clinician, setClinician] = useState({});
  const [filter, setFilter] = useState({
    sortBy: '',
    status: '',
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [reportPopup, setReportPopup] = useState(false);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const click = useRef(null);
  const filterRef = useRef(null);
  const navigate = useNavigate();
  const classes = useStyles();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deleteUser, setDeleteUser] = useState({});

  const handleChangePage = async (event, newPage) => {
    await getClinicians(filter.sortBy, search, filter.status, newPage + 1);
    setPage(newPage);
  };

  const handleFilterChange = async (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
    await getClinicians(filter.sortBy, search, filter.status);
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);
    await getClinicians(filter.sortBy, event.target.value, filter.status);
  };

  useEffect(() => {
    if (filter.sortBy || filter.status) {
      getClinicians(filter.sortBy, search, filter.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    const handleCloseFilter = (e) => {
      if (
        click.current &&
        filterRef.current &&
        openFilter &&
        !click.current.contains(e.target) &&
        !filterRef.current.contains(e.target)
      ) {
        setOpenFilter(false);
      }
    };
    document.addEventListener('mousedown', handleCloseFilter);

    return () => {
      document.removeEventListener('mousedown', handleCloseFilter);
    };
  }, [openFilter]);

  const handleDeleteClinician = (item) => {
    setOpenDeletePopup(true);
    setDeleteUser(item);
  };

  const DeleteUserPopup = openDeletePopup && (
    <DeleteModal
      openDeletePopup={openDeletePopup}
      setOpenDeletePopup={setOpenDeletePopup}
      item={deleteUser}
      getData={getClinicians}
      txt="clinics/deleteClinician"
    />
  );
  return (
    <div
      style={{
        marginTop: '10vh',
        height: '90vh',
      }}
      className="allClinicAdminTable"
      id="clinicAdminTable"
    >
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '20px',
          boxShadow: '0',
          maxHeight: '95%',
          overflowX: 'hidden',
          width: '94vw',
          minHeight: '95%',
        }}
      >
        <div className="allTableHead">
          <h1 className="clientTableHead">
            List of Clinicians
            <span className="countStyle">{clinicians?.total?.count || 0}</span>
          </h1>

          <div className="allTableTitle" style={{ flexGrow: '0.15' }}>
            {/* <FormControlLabel
              required
              control={
                <>
                  <Typography sx={{ color: checked ? '#136773' : '', fontSize: '12px' }}>
                    Active Users
                  </Typography>
                  <Switch
                    size="small"
                    color="default"
                    checked={!checked}
                    onChange={handleChangeCheck}
                    inputProps={{ 'aria-label': 'ant design' }}
                  />
                  <Typography sx={{ color: checked ? '' : '#136773', fontSize: '12px' }}>
                    Inactive Users
                  </Typography>
                </>
              }
            /> */}
            <div className="button-container">
              <button
                className={checked ? 'button active' : 'button inactive'}
                onClick={handleChangeCheck}
              >
                Active Users
              </button>
              <button
                className={checked ? 'button inactive' : 'button active'}
                onClick={handleChangeCheck}
              >
                Inactive Users
              </button>
            </div>
            <IconTextField value={search} handleChange={handleSearch} name="search" />

            <Filter
              className="pointer"
              onClick={() => setOpenFilter(!openFilter)}
              ref={filterRef}
            />
            {user?.role === 'clinic' && (
              <Button className="dashboard_table_head_btn" onClick={() => setReportPopup(true)}>
                Export Report
              </Button>
            )}
            {openFilter && (
              <div className="filterPoper" ref={click}>
                <div className="filterHead">
                  <p>Filters</p>{' '}
                  <p
                    role="presentation"
                    onClick={() => {
                      setFilter('');
                      setSearch('');
                    }}
                    className="pointer"
                  >
                    Clear All
                  </p>
                </div>

                <div>
                  <p className="filterHead">Status</p>
                  <div className="status_bottom" />
                  <div className="hl" />
                  <RadioGroup
                    aria-label="statusFilter"
                    name="status"
                    onChange={handleFilterChange}
                    value={filter.status}
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    {/* <FormControlLabel
                      value="active"
                      control={
                        <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                      }
                      label="Active"
                    />
                    <FormControlLabel
                      value="inactive"
                      control={
                        <Radio classes={{ root: classes.radio, checked: classes.checked }} />
                      }
                      label="Inactive"
                    /> */}
                  </RadioGroup>
                </div>

                <p className="filterHead">Sort By</p>
                <div className="hl" />
                <RadioGroup
                  aria-label="sortFilter"
                  name="sortBy"
                  onChange={handleFilterChange}
                  value={filter.sortBy}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                >
                  <FormControlLabel
                    value="clinician"
                    control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                    label="Clinician Name"
                  />

                  <FormControlLabel
                    value="clinic"
                    control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                    label="Clinic Name"
                  />
                  <FormControlLabel
                    value="patient"
                    control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                    label="Number of Patients"
                  />
                  {/* <FormControlLabel
                    value="patient-active"
                    control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                    label="Active Patients"
                  />
                  <FormControlLabel
                    value="patient-in-active"
                    control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                    label="Inactive Patients"
                  /> */}
                  <FormControlLabel
                    value="patient-error"
                    control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                    label="Patients Not Performing well"
                  />
                </RadioGroup>
              </div>
            )}
          </div>
        </div>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
            paddingBottom: '5%',
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow sx={{ height: '20px' }}>
              <TableCell sx={{ width: '2%' }} />
              <TableCell align="left" sx={{ width: '20%' }} className="table_head">
                Name
              </TableCell>
              <TableCell className="table_head" align="center">
                Title
              </TableCell>
              <TableCell className="table_head" align="center">
                Phone
              </TableCell>
              <TableCell className="table_head" align="center">
                Patients
              </TableCell>{' '}
              <TableCell className="table_head" align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <LinearProgress
                    sx={{
                      '& .MuiLinearProgress-barColorPrimary': {
                        backgroundColor: '#f6fafb',
                      },
                      '& .MuiLinearProgress-barColorSecondary': {
                        backgroundColor: '#f6fafb',
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            ) : clinicians?.users?.length > 0 ? (
              clinicians?.users?.map((row) => (
                <StyledTableRow key={row.id} className="tableRow">
                  <TableCell />
                  <TableCell
                    align="left"
                    sx={{ display: 'flex', paddingTop: '12%' }}
                    onClick={() => navigate(`/clinicians/${row.id}`)}
                  >
                    {row?.profile_pic ? (
                      <div className="imgDivClinic">
                        <img
                          src={getMediaUrl(row.profile_pic)}
                          style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '100px',
                            objectFit: 'cover',
                          }}
                          alt=""
                        />
                      </div>
                    ) : (
                      <AvatarComponent
                        alt={`${row.first_name} ${row.last_name}`}
                        className="header_avatar"
                      />
                    )}
                    <div className="clinic_cell">
                      {`${row.first_name} ${row.last_name}`} <br />
                      <span className="clinicNametxt">
                        Clinic: <span className="clinicNameSubtxt">{row.clinic_name}</span>
                      </span>
                    </div>
                  </TableCell>

                  <TableCell
                    align="center"
                    className="table_cells"
                    onClick={() => navigate(`/clinicians/${row.id}`)}
                  >
                    {row?.userData?.title ? row?.userData?.title : '-'}.
                  </TableCell>
                  <TableCell
                    align="center"
                    className="table_cells"
                    onClick={() => navigate(`/clinicians/${row.id}`)}
                  >
                    {PhoneFormat(row.phone_number)}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="table_cells"
                    sx={{
                      padding: '3%',
                    }}
                    onClick={() => navigate(`/clinicians/${row.id}`)}
                  >
                    {row.patientActive.count}
                  </TableCell>

                  <TableCell align="center">
                    {checked ? (
                      <>
                        <Edit
                          className="table_icons"
                          onClick={() => {
                            setOpenEditClinician(true);
                            setClinician(row);
                          }}
                        />
                        <VisibilityOff
                          className="table_icons"
                          onClick={() => handleDeleteClinician(row)}
                          style={{ marginLeft: '.5rem' }}
                        />
                      </>
                    ) : (
                      <button
                        type="button"
                        onClick={() => activateUser(row.id)}
                        className="action_btn"
                      >
                        Activate
                      </button>
                    )}
                  </TableCell>
                </StyledTableRow>
              ))
            ) : (
              <>
                {' '}
                <TableCell align="center" />
                <TableCell align="left" className="noDataTxt">
                  No Clinicians found
                </TableCell>
              </>
            )}
          </TableBody>
        </Table>

        <EditClinician
          openEditClinican={openEditClinician}
          setOpenEditClinican={setOpenEditClinician}
          clinicianDetails={clinician}
          getClinicians={getClinicians}
        />
        {DeleteUserPopup}
      </TableContainer>
      <TablePagination
        sx={{ color: '#919B9B', width: '97%' }}
        component="div"
        rowsPerPageOptions={[]}
        count={clinicians?.total?.count || 0}
        rowsPerPage={clinicians.limit || 10}
        page={page}
        onPageChange={handleChangePage}
      />
      {reportPopup && (
        <ExportReport
          openNotes={reportPopup}
          setOpenNotes={setReportPopup}
          userData={user}
          userId={user?.id}
        />
      )}
    </div>
  );
}
