import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Completed } from '../../../assets/components/Sidebar/Completed.svg';
// import { ReactComponent as Activity } from '../../../assets/components/TimeCard/activity.svg';
import { ReactComponent as Msg } from '../../../assets/components/Sidebar/msg.svg';
import PatientSidebarSection from './PatientSidebarSection';
import { ReactComponent as Note } from '../../../assets/components/Sidebar/Notes.svg';
import PatientNotes from '../../popups/general/PatientNotes.Popup';
import request from '../../../request/index';

export default function Sidebar({
  patientId,
  patientData,
  selectedClinician,
  openEditpatientModal,
  setOpenEditpatientModal,
}) {
  const navigate = useNavigate();
  const [activityClicked, setActivityClicked] = useState(false);
  const [activityClickedTwo, setActivityClickedTwo] = useState(false);
  const [activityClickedThree, setActivityClickedThree] = useState(false);
  const [patientNotes, setPatientNotes] = useState();

  const handleTimeTracker = () => {
    if (patientId) {
      request('get', `/clinicians/notes/${patientId}`)
        .then((response) => {
          setPatientNotes(response?.data?.notes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    handleTimeTracker();
    // Set the activityClicked state based on the current URL path
    const path = window.location.pathname;
    if (path === `/patients/${patientId}/addPrescription/${selectedClinician}`) {
      setActivityClicked(true);
      setActivityClickedTwo(false);
      setActivityClickedThree(false);
    } else if (path === `/patients/${patientId}/messages/${selectedClinician}`) {
      setActivityClickedTwo(true);
      setActivityClicked(false);
      setActivityClickedThree(false);
    }
    //  else if (path === `/patients/${patientId}/activity`) {
    //   setActivityClickedThree(true);
    //   setActivityClicked(false);
    //   setActivityClickedTwo(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, selectedClinician]);

  return (
    <div className="left_side">
      <PatientSidebarSection
        patientData={patientData}
        openEditpatientModal={openEditpatientModal}
        setOpenEditpatientModal={setOpenEditpatientModal}
      />
      <div
        className="activityLeft"
        role="presentation"
        onClick={() => {
          setActivityClicked(true);
          setActivityClickedTwo(false);
          setActivityClickedThree(false);
          navigate(`/patients/${patientId}/addPrescription/${selectedClinician}`);
        }}
      >
        <div className="image_div">
          <Completed
            className={
              activityClicked || window.location.pathname === '/patients/:id/addPrescription'
                ? 'iconActivity'
                : 'notActivity'
            }
          />
        </div>
        <p
          className={
            activityClicked || window.location.pathname === '/patients/:id/addPrescription'
              ? 'clickedActivity'
              : 'activityTxt'
          }
        >
          Prescribe Exercise
        </p>
      </div>
      <div
        className="activityLeft"
        role="presentation"
        onClick={() => {
          navigate(`/patients/${patientId}/messages/${selectedClinician}`);
          setActivityClickedTwo(true);
          setActivityClicked(false);
          setActivityClickedThree(false);
        }}
      >
        <div className="image_div">
          <Msg
            className={
              activityClickedTwo ||
              window.location.pathname === `/patients/${patientId}/messages/${selectedClinician}`
                ? 'iconActivity'
                : 'notActivity'
            }
          />
        </div>
        <p
          className={
            activityClickedTwo ||
            window.location.pathname === `/patients/${patientId}/messages/${selectedClinician}`
              ? 'clickedActivity'
              : 'activityTxt'
          }
        >
          Message
        </p>
      </div>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {
          console.log('none');
        }}
        onClick={() => {
          setActivityClickedTwo(false);
          setActivityClicked(false);
          setActivityClickedThree(true);
        }}
        className={activityClickedThree ? 'selectedExActivity' : 'activityEx'}
      >
        <div className="image_div">
          <Note className="exIcon" />
        </div>
        <p
          className={
            activityClickedThree ||
            window.location.pathname === `/patients/${patientId}/activity/${selectedClinician}`
              ? 'activityTxt'
              : 'activityTxt'
          }
        >
          Notes
        </p>
      </div>
      {activityClickedThree && (
        <PatientNotes
          openNotes={activityClickedThree}
          setOpenNotes={setActivityClickedThree}
          NotesData={patientNotes}
          ClinicianId={selectedClinician}
          PatientID={patientId}
          handleTimeTracker={handleTimeTracker}
        />
      )}
      {/* <p className="totalActivity">Total Activity</p> */}
      {/* <p className="hrsTxt">(129 Hrs)</p> */}
    </div>
  );
}
