import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { labels, options } from '../../../utils/data';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export default function Chart({ clinicsPerMonth }) {
  const [chartArray, setChartArray] = useState([]);

  const chartData = () => {
    const tempArray = [];
    labels?.forEach((item) => {
      const found = clinicsPerMonth?.find((count) => count?.month?.toUpperCase() === item);
      if (found) {
        tempArray.push(found?.count);
      } else {
        tempArray.push(0);
      }
    });
    setChartArray(tempArray);
  };

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: chartArray,
        backgroundColor: 'rgb(251, 166, 34)',
        barPercentage: 0.2,
      },
    ],
  };

  useEffect(() => {
    chartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicsPerMonth]);

  return (
    <>
      <div className="chart_header_div">
        <span className="dashboardChartTxt">Clinics Added</span>
        <span className="chart_header_span">
          <div className="chart_header_orange_dot" />
          Clinics Counter
        </span>
      </div>
      <Bar options={options} data={data} />
    </>
  );
}
