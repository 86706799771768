import { React, useEffect, useState } from 'react';
import Breadcrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import SurveyPain from '../../../components/PainSurvey/PainSurvey.Component';

export default function PainSurvey({
  session,
  prescription,
  breadCrumb,
  sessionID,
  activeTab,
  handleBackIcon,
}) {
  const [survey, setSurvey] = useState(session?.survey);

  useEffect(() => {
    setSurvey(session?.survey);
  }, [session]);
  return (
    <div style={{ marginLeft: 'auto', width: '100%' }}>
      <div className="bgMsg" style={{ marginLeft: 'unset', width: '100%' }}>
        <Breadcrumbs ClinicName={breadCrumb} sessionID={sessionID} activeTab={activeTab} />
        <div className="bgPainSurvey">
          <SurveyPain
            handleBackIcon={handleBackIcon}
            session={session}
            prescription={prescription}
            survey={survey}
          />
        </div>
      </div>
    </div>
  );
}
