// let baseUrl = 'http://localhost:5000/api/v1';
// let socketURI = 'http://localhost:5000';

let baseUrl = 'https://api.staging.screnrhealth.com/api/v1';
let socketURI = 'https://api.staging.screnrhealth.com';

if (window.location.host === 'screnr-admin-dev.falconweb.app') {
  baseUrl = 'https://screnr-api-dev.falconweb.app/api/v1';
  socketURI = 'https://screnr-api-dev.falconweb.app';
} else if (window.location.host === 'screnr-admin-qa.falconweb.app') {
  baseUrl = 'https://screnr-api-qa.falconweb.app/api/v1';
  socketURI = 'https://screnr-api-qa.falconweb.app';
} else if (window.location.host === 'screnr-admin-staging.falconweb.app') {
  baseUrl = 'https://screnr-api-staging.falconweb.app/api/v1';
  socketURI = 'https://screnr-api-staging.falconweb.app';
} else if (window.location.host === 'portal.screnrhealth.com') {
  baseUrl = 'https://api.screnrhealth.com/api/v1';
  socketURI = 'https://api.screnrhealth.com';
} else if (window.location.host === 'staging.screnrhealth.com') {
  baseUrl = 'https://api.staging.screnrhealth.com/api/v1';
  socketURI = 'https://api.staging.screnrhealth.com';
}

const getMediaUrl = (url) => {
  if (typeof url === 'string') {
    return baseUrl.replace('/api/v1', '/') + url;
  }
  return URL.createObjectURL(url);
};

// const timezoneOffset = new Date().getTimezoneOffset() / -60;
const timezoneOffset = -7;

module.exports = { baseUrl, getMediaUrl, socketURI, timezoneOffset };
