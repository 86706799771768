import { React, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import AllPatientTable from '../../components/tables/patient/AllPatientsTable';
import AddNewPatient from '../../components/popups/patient/AddPatient.Popup';
import request from '../../request';
import { Store } from '../../StoreContext';
import { timeoutDuration, eventsToTrack } from '../../utils/allEvents';

export default function Patient() {
  const [patients, setPatients] = useState();
  const [loading, setLoading] = useState(false);
  const [exerciseFilter, setExerciseFilter] = useState([]);
  const [openAddPatient, setOpenAddPatient] = useState(false);
  const [diaganosisFilter, setDiaganosisFilter] = useState([]);
  const { user, socket } = Store();
  const location = useLocation();
  const [apiUrl, setApiUrl] = useState('/clinicians/allPatientsListWithFilters?sort=');
  const [bodyObject, setBodyObject] = useState({
    filter_type: '',
    filter_value: '',
  });

  let isActive = true;
  const handleInactiveEvent = () => {
    isActive = true;
    if (patients?.users?.length) {
      socket.emit('activity:stop', {
        clinician_id: user?.id,
        patient_ids: patients?.users?.map((item) => item.id),
        page_name: location?.pathname,
        start_time: Date.now(),
      });
    }
  };
  // resetting timeout on each time eventcalls
  const resetTimeout = () => {
    clearTimeout(window.timeout);
    window.timeout = setTimeout(handleInactiveEvent, timeoutDuration);
  };
  // sending start activity object
  const handleEvent = () => {
    // boolean ensures to emit the object first time function is called
    // array is necessary to check so object doesn't go empty
    if (isActive && patients?.users?.length) {
      isActive = false;
      if (user && socket) {
        socket.emit('activity:start', {
          clinician_id: user?.id,
          patient_ids: patients?.users?.map((item) => item.id),
          page_name: location?.pathname,
          start_time: Date.now(),
        });
      }
      // timeout starts as the user land
      resetTimeout();
    } else {
      // after initialization only timeout is called
      resetTimeout();
    }
  };

  const [filter, setFilter] = useState({
    filterType: 'all',
    clinicNamefilter: '',
    exerciseArray: [],
    diagnosisArray: [],
  });

  const [sorting, setSorting] = useState({
    sortBy: '',
  });
  const [checked, setChecked] = useState(true);

  const handleChangeCheck = () => {
    setChecked(!checked);
  };

  const handleFilterAndSorting = async (item) => {
    const { filterType, clinicName, filterValueArray } = item || {};

    if (filterType === 'all') {
      setBodyObject((prevBodyObject) => ({
        ...prevBodyObject,
        filter_type: filterType,
        filter_value: '',
      }));
    } else if (filterType === 'clinic') {
      setBodyObject((prevBodyObject) => ({
        ...prevBodyObject,
        filter_type: filterType,
        filter_value: clinicName,
      }));
    } else if (filterType === 'exercise' || filterType === 'diagnosis') {
      setBodyObject((prevBodyObject) => ({
        ...prevBodyObject,
        filter_type: filterType,
        filter_value: filterValueArray,
      }));
    }

    if (item?.sort) {
      setApiUrl(`/clinicians/allPatientsListWithFilters?sort=${item?.sort}`);
    }
    if (item?.searchValue !== undefined && item.searchValue !== null) {
      setApiUrl((prevUrl) => {
        let updatedUrl = prevUrl;
        const searchParam = `search=${item.searchValue}`;

        // Check if search parameter already exists in the URL
        const searchExists = updatedUrl.includes('search=');

        if (item.searchValue === '') {
          // Clear the search parameter
          updatedUrl = updatedUrl.replace(/(\?|&)search=([^&]*)/, '');
        } else if (searchExists) {
          // Replace the existing search parameter with the new one
          updatedUrl = updatedUrl.replace(/(\?|&)search=([^&]*)/, `$1${searchParam}`);
        } else {
          // Append the search parameter
          updatedUrl += (updatedUrl.includes('?') ? '&' : '?') + searchParam;
        }

        return updatedUrl;
      });
    } else {
      // If search value is null or undefined, fetch the full list of data
      setApiUrl((prevUrl) => prevUrl.replace(/(\?|&)search=([^&]*)/, ''));
    }
    if (typeof filterValueArray === 'object' && filterValueArray?.length === 0) {
      setBodyObject((prevBodyObject) => ({
        ...prevBodyObject,
        filter_type: 'all',
        filter_value: '',
      }));
    }
  };

  const fetchData = async (page) => {
    try {
      setLoading(true);
      const url = `${apiUrl}&page=${page || 1}&active=${checked}`;
      const res = await request('put', url, bodyObject);
      if (res?.data) {
        setPatients(res?.data);
      } else {
        setPatients();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const activateUser = (userId) => {
    request('patch', `/clinicians/activatePatient/${userId}`)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        console.log(' ~ file: AllPatients.Page.jsx:97 ~ activateUser ~ err:', err);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl, bodyObject, checked]);

  const handleDynamicFilter = async () => {
    try {
      const { data } = await request('get', '/clinicians/getDynamicFilters');
      if (data) {
        setExerciseFilter(data?.exercises);
        setDiaganosisFilter(data?.diagnosis);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  // first time emit start event
  useEffect(() => {
    // array is necessary to check so object doesn't go empty
    if (patients?.users?.length && user.role === 'clinician') {
      handleEvent();
      eventsToTrack.forEach((eventName) => {
        window.addEventListener(eventName, handleEvent);
      });
    }

    return () => {
      eventsToTrack.forEach((eventName) => {
        window.removeEventListener(eventName, handleEvent);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients?.users?.length]);

  useEffect(() => {
    handleDynamicFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    handleFilterAndSorting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid sx={{ width: '100%' }} className="bgClinicianTable">
        <AllPatientTable
          filter={filter}
          sorting={sorting}
          loading={loading}
          patients={patients}
          setFilter={setFilter}
          setSorting={setSorting}
          getPatients={fetchData}
          exerciseFilter={exerciseFilter}
          diaganosisFilter={diaganosisFilter}
          setOpenAddPatient={setOpenAddPatient}
          handleFilterAndSorting={handleFilterAndSorting}
          checked={checked}
          activateUser={activateUser}
          handleChangeCheck={handleChangeCheck}
        />
      </Grid>
      {openAddPatient && (
        <AddNewPatient
          getPatients={fetchData}
          openAddPatient={openAddPatient}
          setOpenAddPatient={setOpenAddPatient}
        />
      )}
    </Grid>
  );
}
