import { React, useState } from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { FormControl, Button } from '@mui/material';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ReactComponent as Phone } from '../../../assets/icons/phoneIcon.svg';
import { ReactComponent as Mail } from '../../../assets/icons/mailIcon.svg';
import { ReactComponent as Arrow } from '../../../assets/icons/logoutArrow.svg';
import edit from '../../../assets/icons/sidebarEdit.svg';
import AvatarComponent from '../../Avatar/Avatar.Component';
import { Store, UpdateStore } from '../../../StoreContext';
import { getMediaUrl } from '../../../config';
import PhoneFormat from '../../../utils/PhoneFormat';
import request from '../../../request';
import projectVersion from '../../../utils/projectVersion';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-root': {
    borderRadius: '25px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(7),
    textAlign: 'center',
  },
  '& .MuiDialogActions-root': {
    paddingBottom: theme.spacing(7),
    paddingTop: theme.spacing(0),
    margin: 'auto',
  },
  [theme.breakpoints.down('1800')]: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
      textAlign: 'center',
    },
  },
}));

export default function ProfilePopup({
  openProfilePopup,
  setOpenProfilePopup,
  setOpenPasswordPopup,
  setOpenEditProfilePopup,
}) {
  const { user } = Store();
  const updateStore = UpdateStore();
  const handleClose = () => {
    setOpenProfilePopup(false);
  };
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    setLoading(true);
    request('patch', '/auth/logout/')
      .then(() => {
        window.location = '/login';
        localStorage.removeItem('token');
        updateStore({ user: {}, loggedIn: false });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const roles = {
    admin: 'Super Admin',
    staff: 'Staff Admin',
    clinic: 'Clinic Admin',
    clinician: 'Clinician',
    patient: 'Patient',
  };

  return (
    <div style={{ display: 'none' }}>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openProfilePopup}
        setOpen={setOpenProfilePopup}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
            background: 'white',
            width: 750,
          },
        }}
      >
        <DialogContent>
          <div className="loginClearIconCopy">
            <ClearIcon onClick={handleClose} />
          </div>
          <div>
            <Typography gutterBottom>
              <div className="imgDivPopupProfile">
                <label htmlFor="profilePic">
                  {user?.profile_pic ? (
                    <img
                      src={getMediaUrl(user?.profile_pic)}
                      className="pointer"
                      id="selectedImg"
                      style={{
                        width: '122px',
                        borderRadius: '100px',
                        height: '100%',
                        objectFit: 'cover',
                        border: ' 3px solid #faa017',
                      }}
                      alt=""
                    />
                  ) : (
                    <AvatarComponent
                      alt={`${user.first_name ?? ''} ${user.last_name ?? ''}`}
                      className="profile_popups_avatar"
                    />
                  )}
                  <div className="tooltip">
                    <img
                      src={edit}
                      className="addDPIcon"
                      role="presentation"
                      onClick={() => {
                        setOpenEditProfilePopup(true);
                        setOpenProfilePopup(false);
                      }}
                      alt=""
                    />
                    <span className="tooltiptext">Edit Profile</span>
                  </div>
                </label>
              </div>

              <h1 className="forgetPaswdTxt">{`${user.first_name || ''} ${
                user.last_name || ''
              }`}</h1>
              <p className="enterEmailTxt">{roles[user?.role]}</p>
              <FormControl sx={{ mb: 2, mt: 6, width: '85%' }} variant="outlined">
                <div className="pswdChangeTrack" htmlFor="phone">
                  <p className="inputDiv">
                    <Phone className="customFieldIcon" /> Phone Number
                  </p>
                  <p className="blktxt" id="fontWeight">
                    {' '}
                    {user?.phone_number ? PhoneFormat(user?.phone_number) : ''}
                  </p>
                </div>
              </FormControl>
              <FormControl sx={{ mb: 2, width: '85%' }} variant="outlined">
                <div className="pswdChangeTrack" htmlFor="email">
                  <p className="inputDiv">
                    <Mail className="customFieldIcon" /> Email
                  </p>
                  <p className="blktxt" id="fontWeight">
                    {user?.email ?? ''}
                  </p>
                </div>
              </FormControl>
              <FormControl sx={{ mb: 2, width: '85%' }} variant="outlined">
                <div className="pswdChangeTrack">
                  <p>Last Password Change</p>
                  <p>
                    {user?.last_password_changed
                      ? moment(user?.last_password_changed).format('MM-DD-YYYY')
                      : ''}
                  </p>
                </div>
              </FormControl>
              <FormControl sx={{ mb: 2, width: '85%' }} variant="outlined">
                <div className="pswdChangeTrack">
                  <p className="blktxt">Password</p>
                  <p
                    className="passwordChangeTxt"
                    role="presentation"
                    onClick={() => {
                      setOpenPasswordPopup(true);
                      setOpenProfilePopup(false);
                    }}
                  >
                    Change Password
                  </p>
                </div>
              </FormControl>
              <FormControl sx={{ mb: 1, width: '85%' }} variant="outlined">
                <div className="pswdChangeTrack">
                  <p className="blktxt">Account Created</p>
                  <p className="blktxt" id="boldx">
                    {user?.createdAt ? moment(user?.createdAt).format('MM-DD-YYYY') : ''}
                  </p>
                </div>
              </FormControl>
            </Typography>
          </div>
        </DialogContent>

        <DialogActions sx={{ width: '84%', display: 'flex', flexDirection: 'column' }}>
          <Button
            onClick={handleLogout}
            className={loading ? 'logoutBtnloading' : 'logoutBtn'}
            variant="contained"
            disabled={loading}
          >
            {loading && (
              <div style={{ height: 'inherit' }} className="progressContainer">
                <CircularProgress className="whiteCircularProgress" size={20} />
              </div>
            )}
            <p className="logoutTxt"> Logout</p>
            <Arrow className="logoutArrow" />
          </Button>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '10px',
            }}
          >
            <p
              style={{
                fontSize: '9px',
                fontFamily: 'Poppins',
                color: '#126773',
                fontWeight: '600',
                // margin: '10px',
                opacity: 0.7,
              }}
            >
              VERSION {projectVersion}
            </p>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
