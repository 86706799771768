import Button from '@mui/material/Button';
import { React, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import { FormControl, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import request from '../../../request/index';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-root': {
    borderRadius: '20px',
  },
  '& .MuiDialogContent-root': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  '& .MuiDialogActions-root': {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(7),
  },
}));
const useOutlinedInputStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #E1E1E1 ',
        borderRadius: '15px',
        fontSize: 16,
        height: 55,
      },
      '&:hover fieldset': {
        borderColor: '#454238',
      },
      '&::placeholder fieldset': {
        fontWeight: 800,
      },
      '&.Mui-focused fieldset': {
        borderColor: '#454238',
      },
    },
  },
});

export default function PatientNotes({
  openNotes,
  setOpenNotes,
  NotesData,
  ClinicianId,
  PatientID,
  handleTimeTracker,
}) {
  const outlinedInputStyles = useOutlinedInputStyles();
  const [NewNote, setNewNote] = useState();
  const handleClose = () => {
    setOpenNotes(false);
  };
  const handleSaveClick = () => {
    const body = { patient_id: PatientID, clinician_id: ClinicianId, message: NewNote };
    request('post', `/clinicians/notes`, body)
      .then((res) => {
        console.log(res);
        toast.success('Note Added');
        handleTimeTracker();
        setNewNote('');
        // handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openNotes}
        setOpen={setOpenNotes}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { borderRadius: 20, background: '#FFFFFF' },
        }}
      >
        <DialogContent>
          <div className="loginClearIconCopy">
            <ClearIcon onClick={handleClose} />
          </div>
          <div>
            {NotesData?.length > 0 && (
              <>
                <Typography>
                  <h1 className="genPresTxt">Notes</h1>
                  <p className="genSubTxt">Your saved notes!</p>
                </Typography>
                {NotesData?.map((sentence, index) => (
                  <>
                    <div className="noteAddedByName">
                      <p className="notedAddedPerson">
                        {' '}
                        {sentence?.user?.first_name} {sentence?.user?.last_name}
                      </p>{' '}
                      <p className="notedAddedPerson">
                        ({sentence?.user?.role === 'clinic' ? 'Clinic Admin' : sentence?.user?.role}
                        )
                      </p>
                    </div>
                    <FormControl key={index.id} sx={{ mb: 2, width: '85%' }} variant="outlined">
                      <label className="pswdChangeTrack" htmlFor={`sentence-${index}`}>
                        <p>{sentence?.message}</p>
                        <p>#</p>
                      </label>
                    </FormControl>
                  </>
                ))}
              </>
            )}

            <h2 className="genPresTxt">Add New Note</h2>
            <p className="genSubTxt">Enter text and save</p>
            <FormControl sx={{ mb: 2, width: '85%' }} variant="outlined">
              <TextField
                sx={{
                  borderRadius: '15px',
                  input: {
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: '500',
                    ml: 1,
                  },
                }}
                id="outlined-name"
                classes={outlinedInputStyles}
                placeholder="Enter note text..."
                size="normal"
                name="noteVal"
                value={NewNote}
                onChange={(e) => setNewNote(e.target.value)}
                // inputProps={{
                //   maxLength: 50,
                // }}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions sx={{ width: '100%' }}>
          <Button onClick={handleSaveClick} className="tryAnotherBtn" variant="contained">
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
