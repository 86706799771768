/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { getMediaUrl } from '../../config';

const index = ({
  selectedImageIndex,
  handleClose,
  handlePrevImage,
  handleNextImage,
  screenshots,
}) => {
  const sideName = screenshots[selectedImageIndex].split('_')[1];
  const setName = screenshots[selectedImageIndex].split('_')[2];
  const repName = screenshots[selectedImageIndex].split('_')[3].split('.')[0];
  const imageOrientation = screenshots[selectedImageIndex].split('_')[4].split('.')[0];

  const isFirstImage = selectedImageIndex === 0;
  const isLastImage = selectedImageIndex === screenshots.length - 1;

  return (
    <div className="screenshotReviewer-main-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        <span style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'white' }}>
          Side : {sideName}
        </span>
        <span style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'white' }}>
          Set no. : {setName}
        </span>
        <span style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'white' }}>
          Rep no. : {repName}
        </span>
        <img
          src={getMediaUrl(`${screenshots[selectedImageIndex]}`)}
          alt="Selected Image"
          className="screenshotReviewer-image"
          style={{
            transform: imageOrientation === 'portrait' ? 'scaleX(-1)' : 'rotate(90deg) scaleX(-1)',
            filter: 'FlipH',
            width: imageOrientation === 'portrait' ? '' : '60vh',
            // height: imageOrientation === 'portrait' ? '' : '70vw',
          }}
        />
      </div>

      <div onClick={handleClose} className="screenshotReviewer-image-close-btn">
        &times;
      </div>
      <div
        disabled={isFirstImage}
        onClick={handlePrevImage}
        className="screenshotReviewer-image-next-btn"
        style={{
          visibility: isFirstImage ? 'hidden' : 'visible',
        }}
      >
        &#8249;
      </div>
      <div
        disabled={isLastImage}
        onClick={handleNextImage}
        className="screenshotReviewer-image-prev-btn"
        style={{
          visibility: isLastImage ? 'hidden' : 'visible',
        }}
      >
        &#8250;
      </div>
    </div>
  );
};

export default index;
