import io from 'socket.io-client';
import { socketURI } from '../config';

const socketConnection = () => {
  let newSocket = null;
  const token = localStorage.getItem('token');
  if (token) {
    newSocket = io(`${socketURI}/activity`, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    newSocket.on('connected', () => {
      console.log('Socket connection successful');
    });
  }

  return newSocket;
};
export default socketConnection;
