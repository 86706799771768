import React from 'react';
import GaugeChart from 'react-gauge-chart';

const chartStyle = {
  height: 70,
  width: '100px',
};
function LiveGaugeChart({ value }) {
  return (
    <div>
      <GaugeChart
        id="gauge-chart1"
        style={chartStyle}
        arcsLength={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
        colors={[
          'rgba(81, 170, 132, 1)',
          'rgba(100, 200, 97, 1)',
          'rgba(191, 201, 55, 1)',
          'rgba(241, 227, 50, 1)',
          'rgba(253, 216, 49, 1)',
          'rgba(253, 187, 44, 1)',
          'rgba(253, 144, 37, 1)',
          'rgba(252, 102, 34, 1)',
          'rgba(219, 44, 24, 1)',
          'rgba(184, 54, 51, 1)',
        ]}
        percent={value}
        needleBaseColor="#136773"
        needleColor="#136773"
        cornerRadius={0}
        arcPadding={0.02}
        animate={false}
        textColor="black"
        // eslint-disable-next-line no-shadow
        formatTextValue={(value) => `${value.toFixed(2)}%`}
        hideText
      />
    </div>
  );
}

export default LiveGaugeChart;
