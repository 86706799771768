import { React, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Sidebar from '../../components/sidebar/patient/PatientSidebar';
import PatientTopSection from '../../components/PatientTopSection/PatientTopSection.Component';
import PrescriptionTable from '../../components/tables/prescription/PrescriptionTable';
import request from '../../request';
import EditPatient from '../../components/popups/patient/EditPatient.Popup';
import addBtn from '../../assets/icons/addBtn.svg';
import Breadcrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { Store } from '../../StoreContext';
import { timeoutDuration, eventsToTrack } from '../../utils/allEvents';

export default function Prescription() {
  const { user, socket } = Store();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [patient, setPatient] = useState('');
  const [allClinicians, setAllClinicians] = useState('');
  const [openEditpatientModal, setOpenEditpatientModal] = useState(false);
  const [selectedClinician, setSelectedClinician] = useState('');
  const [breadcrumb, setBreadcrumb] = useState('');
  const location = useLocation();
  const patientIdArr = [];
  patientIdArr.push(id);
  // boolean for initially emitting start object
  let isActive = true;
  const getPatients = async () => {
    await request('get', `/clinicians/getPatientDetail/${id}`)
      .then(async (res) => {
        setPatient(res?.data?.user);
        setBreadcrumb(`${res?.data?.user?.first_name} ${res?.data?.user?.last_name}`);
        setSelectedClinician(res?.data?.user?.userData?.clinicians[0]?.id);
        const ClinicId = res?.data?.user?.userData?.clinicians[0]?.userData?.clinic;
        await request('get', `/clinicians/getCliniciansByClinicIdNonPaginated/${ClinicId}`)
          .then((res1) => {
            setAllClinicians(res1?.data?.users);
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const EditpatientPopup = openEditpatientModal && (
    <EditPatient
      openEditPatient={openEditpatientModal}
      setOpenEditPatient={setOpenEditpatientModal}
      patientDetails={patient}
      getPatients={getPatients}
    />
  );

  // sending inactivity object
  const handleInactiveEvent = () => {
    // setting boolean true to emit start object again
    isActive = true;
    if (patientIdArr.length) {
      socket.emit('activity:stop', {
        clinician_id: selectedClinician,
        patient_ids: patientIdArr?.map((item) => item),
        page_name: location?.pathname,
        start_time: Date.now(),
      });
    }
  };

  // resetting timeout on each time eventcalls
  const resetTimeout = () => {
    clearTimeout(window.timeout);
    window.timeout = setTimeout(handleInactiveEvent, timeoutDuration);
  };

  // sending start activity object
  const handleEvent = () => {
    // boolean ensures to emit the object first time function is called
    // array is necessary to check so object doesn't go empty
    if (isActive && patientIdArr?.length) {
      isActive = false;
      if (selectedClinician && socket) {
        socket.emit('activity:start', {
          clinician_id: selectedClinician,
          patient_ids: patientIdArr?.map((item) => item),
          page_name: location?.pathname,
          start_time: Date.now(),
        });
      }
      // timeout starts as the user lands
      resetTimeout();
    } else {
      // after initialization only timeout is called
      resetTimeout();
    }
  };

  // first time emit start event
  useEffect(() => {
    // array is necessary to check so object doesn't go empty
    if (patientIdArr?.length && (user.role === 'clinician' || user.role === 'clinic')) {
      handleEvent();
      eventsToTrack.forEach((eventName) => {
        window.addEventListener(eventName, handleEvent);
      });
    }

    return () => {
      eventsToTrack.forEach((eventName) => {
        window.removeEventListener(eventName, handleEvent);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientIdArr?.length, selectedClinician]);

  useEffect(() => {
    getPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <div style={{ position: 'fixed' }} className="gridish">
        <div className="sideBar_div">
          <Sidebar
            patientId={id}
            patientData={patient}
            openEditpatientModal={openEditpatientModal}
            setOpenEditpatientModal={setOpenEditpatientModal}
            selectedClinician={selectedClinician}
          />
        </div>
        <Grid className="bgPrescription" xl={10} lg={9.5} sx={{ height: '100vh' }}>
          <Breadcrumbs ClinicName={breadcrumb} />
          <PatientTopSection
            allClinicians={allClinicians}
            patientData={patient}
            getPatients={getPatients}
            selectedClinician={selectedClinician}
            setSelectedClinician={setSelectedClinician}
            handleInactiveEvent={handleInactiveEvent}
          />
          <PrescriptionTable
            patientId={id}
            // selectedClinicianId={selectedClinician}
            selectedClinicianId={selectedClinician}
            selectedClinicians={patient?.userData?.clinicians}
          />
        </Grid>
        <div className="addBtn">
          <img
            src={addBtn}
            className="pointer"
            onClick={() => {
              navigate(`/patients/${id}/addPrescription/${selectedClinician}`);
            }}
            role="presentation"
            alt=""
          />
        </div>
      </div>
      {EditpatientPopup}
    </>
  );
}
