import ClearIcon from '@mui/icons-material/Clear';
import { CircularProgress, FormControl, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import moment from 'moment';
import { React, useState } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import dropdown from '../../../assets/icons/dropdownOrange.svg';
import { ReactComponent as ExpiryDate } from '../../../assets/icons/expiryDate.svg';
import { baseUrl } from '../../../config';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-root': {
    borderRadius: '20px',
  },
  '& .MuiDialogContent-root': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  '& .MuiDialogActions-root': {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(7),
  },
}));

export default function ExportReport({ openNotes, setOpenNotes, userData, userId }) {
  const [selectedOption, setSelectedOption] = useState('fullReport');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);
  const today = new Date();
  const handleExportReportBtn = () => {
    if (selectedOption === 'SelectedDate' && !startDate) {
      toast.error('please select start date');
      return;
    }
    if (selectedOption === 'SelectedDate' && !endDate) {
      toast.error('please select end date');
      return;
    }
    if (new Date(startDate).valueOf() > new Date(endDate).valueOf()) {
      toast.error('End date should be greater than start date');
      return;
    }
    const endpoint =
      selectedOption === 'SelectedDate'
        ? `${baseUrl}/clinicians/activity/report/${userId}?startedAt=${moment(startDate)
            .startOf('day')
            .toISOString()}&till=${moment(endDate).endOf('date').toISOString()}`
        : `${baseUrl}/clinicians/activity/report/${userId}`;

    if (userId) {
      setLoading(true);
      axios({
        method: 'get',
        url: endpoint,
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.href = url;
          a.download = `${userData?.first_name}_${userData?.last_name}.csv`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          setOpenNotes(false);
          toast.success('Report generated!');
        })
        .catch((error) => {
          toast.error('Something went wrong!');
          console.error('Error:', error);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => setOpenNotes(false)}
        aria-labelledby="customized-dialog-title"
        open={openNotes}
        setOpen={setOpenNotes}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { borderRadius: 20, background: '#FFFFFF' },
        }}
      >
        <DialogContent>
          <div>
            <div className="headingBarExportRep">
              <p className="exportPopupTitle">Export Report</p>

              <div className="loginClearIconCopyExportRep">
                <ClearIcon onClick={() => setOpenNotes(false)} />
              </div>
            </div>
            <FormControl sx={{ mt: 2.5, width: '92%' }} variant="outlined">
              <Select
                // eslint-disable-next-line react/no-unstable-nested-components
                IconComponent={(props) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <img src={dropdown} style={{ margin: '1% 2%' }} {...props} alt="" />
                )}
                displayEmpty
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E1E1E1',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: ' #454238',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: ' #454238',
                  },
                  borderRadius: '20px',
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="body_part"
                placeholder="Title"
                value={selectedOption}
                onChange={({ target }) => setSelectedOption(target.value)}
                inputProps={{
                  sx: { padding: '14.5px', textAlign: 'left', ml: 1 },
                }}
              >
                <MenuItem value="fullReport" key="complete">
                  <span className="optColor">Full Report</span>
                </MenuItem>
                <MenuItem value="SelectedDate" key="Selected Date">
                  <span className="optColor">Selected Date</span>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </DialogContent>

        <FormControl
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '87%',
            textAlign: 'left',
            margin: 'auto',
            marginBottom: '15px',
          }}
        >
          <div
            className="dateDivPrescription"
            style={{ width: '50%', marginRight: '10px' }}
            id={selectedOption !== 'SelectedDate' ? 'disabledDate' : ''}
          >
            <DatePicker
              showPopperArrow
              dateFormat="MM/dd/yyyy"
              name="startDate"
              placeholderText="Date From"
              popperPlacement="left-start"
              showYearDropdown
              yearDropdownItemNumber={2}
              scrollableYearDropdown
              selected={startDate}
              onChange={setStartDate}
              maxDate={today}
              fullWidth
              disabled={selectedOption !== 'SelectedDate'}
            />

            <ExpiryDate className="calenderIconExport" />
          </div>
          <div
            className="dateDivPrescription"
            style={{ width: '50%' }}
            id={selectedOption !== 'SelectedDate' ? 'disabledDate' : ''}
          >
            <DatePicker
              showPopperArrow
              dateFormat="MM/dd/yyyy"
              name="endDate"
              placeholderText="Date To"
              popperPlacement="left-start"
              selected={endDate}
              onChange={setEndDate}
              showYearDropdown
              yearDropdownItemNumber={2}
              scrollableYearDropdown
              maxDate={today}
              fullWidth
              disabled={selectedOption !== 'SelectedDate'}
            />
            <ExpiryDate className="calenderIcon" />
          </div>
        </FormControl>
        <DialogActions sx={{ width: '100%' }}>
          <Button
            className="BtnExportRepClose"
            variant="contained"
            onClick={() => setOpenNotes(false)}
          >
            Close
          </Button>
          <Button className="BtnExportRep" variant="contained" onClick={handleExportReportBtn}>
            {loading && (
              <div style={{ height: 'inherit' }} className="progressContainer">
                <CircularProgress className="whiteCircularProgress" size={20} />
              </div>
            )}
            <p className="sendInviteTxt">Export Report</p>{' '}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
