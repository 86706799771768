import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import Sidebar from '../../components/sidebar/patient/PatientSidebar';
import MessageBox from '../../components/MessageBox/MessageBox.Component';
import request from '../../request/index';
import EditPatient from '../../components/popups/patient/EditPatient.Popup';
import { Store } from '../../StoreContext';
import Breadcrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { socketURI } from '../../config';
import { timeoutDuration, eventsToTrack } from '../../utils/allEvents';

export default function PatientMessageBox() {
  const params = useParams();
  const navigate = useNavigate();
  const { patientId, clinicianId } = params;
  const store = Store();
  const { user, socket } = store;
  const [patient, setPatient] = useState({});
  const [clinician, setClinician] = useState({});
  const [allMessages, setAllMessages] = useState([]);
  const [chatId, setChatId] = useState('');
  const [openEditPatientModal, setOpenEditPatientModal] = useState(false);
  const [lastKey, setLastKey] = useState({});
  const [breadCrumb, setBreadCrumb] = useState('');
  const [messageSocket, setMessageSocket] = useState(null);
  const location = useLocation();
  const patientIdArr = [];
  patientIdArr.push(patientId);

  // boolean for initially emitting start object
  let isActive = true;
  useEffect(() => {
    const token = localStorage.getItem('token');
    let newSocket;
    if (token) {
      newSocket = io(socketURI, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      newSocket.on('connected', () => {
        console.log('Socket connection successful');
      });

      newSocket.on('disconnect', () => {
        console.log('Socket disconnected successful');
      });
      setMessageSocket(newSocket);
    }
    return () => newSocket.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (messageSocket) {
      messageSocket.on(`message${clinicianId}`, (data) => {
        setAllMessages((prev) => [...prev, data]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageSocket, clinicianId]);

  useEffect(() => {
    if (messageSocket && chatId) {
      const readMessage = {
        chat: chatId,
        sender: clinicianId,
        receiver: patientId,
      };

      messageSocket.emit('read', readMessage);

      return () => {
        messageSocket.emit('read', readMessage);
      };
    }
  }, [messageSocket, chatId, clinicianId, patientId]);

  const getPatientDataById = async () => {
    try {
      const response = await request('get', `/clinicians/getPatientDetail/${patientId}`);
      const userData = response?.data?.user;

      setPatient(userData);
      setBreadCrumb(`${userData?.first_name} ${userData?.last_name}`);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const getClinicianById = async () => {
    try {
      const response =
        user?.role === 'clinician'
          ? await request('get', `/auth/userDetails`)
          : await request('get', `/clinics/getClinician/${clinicianId}`);

      setClinician(response?.data?.user);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const chat = async () => {
    try {
      const chatIdResponse = await request('get', `/messages/chatId/${patientId}/${clinicianId}`);
      const chatIdData = chatIdResponse?.data?.chatId;

      setChatId(chatIdData);

      const allMessagesResponse = await request(
        'get',
        `/messages/allMessages/${chatIdData}?limit=50`,
      );
      const reversedMessages = allMessagesResponse?.data?.messages.reverse();

      setAllMessages(reversedMessages);
      setLastKey(allMessagesResponse?.data?.lastKey);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getPatientDataById();
    getClinicianById();
    chat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, clinicianId]);

  const handleSendMessage = (chatID, newMessage, patientID, clinicianID) => {
    if (messageSocket) {
      messageSocket.emit('message', {
        chat: chatID,
        content: newMessage,
        receiver: patientID,
        sender: clinicianID,
      });
    }
  };

  const goBackToProfile = () => {
    navigate(`/patients/${patientId}/prescription`);
  };

  const EditPatientPopup = openEditPatientModal && (
    <EditPatient
      openEditPatient={openEditPatientModal}
      setOpenEditPatient={setOpenEditPatientModal}
      patientDetails={patient}
      getPatients={getPatientDataById}
    />
  );

  // sending inactivity object
  const handleInactiveEvent = () => {
    // setting boolean true to emit start object again
    isActive = true;
    if (patientIdArr.length) {
      socket.emit('activity:stop', {
        clinician_id: clinicianId,
        patient_ids: patientIdArr?.map((item) => item),
        page_name: location?.pathname,
        start_time: Date.now(),
      });
    }
  };

  // resetting timeout on each time eventcalls
  const resetTimeout = () => {
    clearTimeout(window.timeout);
    window.timeout = setTimeout(handleInactiveEvent, timeoutDuration);
  };

  // sending start activity object
  const handleEvent = () => {
    // boolean ensures to emit the object first time function is called
    // array is necessary to check so object doesn't go empty
    if (isActive && patientIdArr?.length) {
      isActive = false;
      if (clinicianId && socket) {
        socket.emit('activity:start', {
          clinician_id: clinicianId,
          patient_ids: patientIdArr?.map((item) => item),
          page_name: location?.pathname,
          start_time: Date.now(),
        });
      }
      // timeout starts as the user lands
      resetTimeout();
    } else {
      // after initialization only timeout is called
      resetTimeout();
    }
  };

  // first time emit start event
  useEffect(() => {
    // array is necessary to check so object doesn't go empty
    if (patientIdArr?.length && (user.role === 'clinician' || user.role === 'clinic')) {
      handleEvent();
      eventsToTrack.forEach((eventName) => {
        window.addEventListener(eventName, handleEvent);
      });
    }

    return () => {
      eventsToTrack.forEach((eventName) => {
        window.removeEventListener(eventName, handleEvent);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientIdArr?.length]);

  return (
    <div style={{ position: 'fixed' }} className="gridish">
      <div className="sideBar_div">
        <Sidebar
          patientId={patientId}
          patientData={patient}
          selectedClinician={clinicianId}
          openEditPatientModal={openEditPatientModal}
          setOpenEditPatientModal={setOpenEditPatientModal}
        />
      </div>
      <div className="bgMsg">
        <Breadcrumbs ClinicName={breadCrumb} />
        <div className="bgAddPrescription">
          <MessageBox
            patient={patient}
            allMessages={allMessages}
            setAllMessages={setAllMessages}
            clinician={clinician}
            chatId={chatId}
            handleSendMessage={handleSendMessage}
            lastKey={lastKey}
            setLastKey={setLastKey}
            goBackToProfile={goBackToProfile}
          />
        </div>
      </div>
      {EditPatientPopup}
    </div>
  );
}
