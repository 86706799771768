import { React } from 'react';
import RightSidebar from '../../../components/sidebar/patient/PatientListRightSidebar';
import ReportCard from '../../../components/cards/ReportCard.Component';
import Breadcrumbs from '../../../components/BreadCrumbs/BreadCrumbs';

export default function Reports({ session, sessionID, activeTab }) {
  const breadCrumb = `${session?.patient?.first_name} ${session?.patient?.last_name}`;
  return (
    <div style={{ display: 'flex' }}>
      <div className="bgReport">
        <Breadcrumbs ClinicName={breadCrumb} sessionID={sessionID} activeTab={activeTab} />
        <div className="bgReportCard">
          {session?.exercises?.map((item) => {
            return (
              <ReportCard
                key={item?.id}
                answer={item?.report?.answer}
                answerTime={item?.report?.answer_time}
                exerciseId={item?.id}
                heading="Exercise: "
                title={item?.name}
                message={item?.report?.report}
                timeStamp={item?.report?.report_time}
                patientName={`${session?.patient?.first_name} ${session?.patient?.last_name}`}
              />
            );
          })}
        </div>
      </div>
      <div>
        <RightSidebar />
      </div>
    </div>
  );
}
