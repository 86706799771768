import { React, useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import request from '../../request';
import Sidebar from '../../components/sidebar/patient/PatientSidebar';
import AddPrescription from '../../components/Forms/AddPrescription/AddPrescription.Component';
import GeneratePrescription from '../../components/popups/prescription/PrescriptionGenerated.popup';
import EditPatient from '../../components/popups/patient/EditPatient.Popup';
import Breadcrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import ExpiryDateExtend from '../../components/popups/prescription/ExpiryDateExtend.Popup';
import { Store } from '../../StoreContext';
import { timeoutDuration, eventsToTrack } from '../../utils/allEvents';

export default function AddPatientPrescription() {
  const { user, socket } = Store();
  const params = useParams();
  const navigate = useNavigate();
  const { patientId, clinicianId } = params;
  const [openGeneratePrescription, setOpenGeneratePrescription] = useState(false);
  const [openEditPatientModal, setOpenEditPatientModal] = useState(false);
  const [patient, setPatient] = useState({});
  const [allMotionExercise, setAllMotionExercise] = useState([]);
  const [exerciseType, setExerciseType] = useState('motion');
  const [disableBtn, setDisableBtn] = useState(false);
  const [openExtendDate, setOpenExtendDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [breadcrumb, setBreadCrumb] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const location = useLocation();
  const patientIdArr = [];
  patientIdArr.push(patientId);

  let isActive = true;

  const [prescription, setPrescription] = useState({
    name: '',
    start_date: new Date(),
    duration: '',
    frequency: '',
    clinician: clinicianId,
    patient: patientId,
    exercises: [],
    type: exerciseType,
  });
  useEffect(() => {
    setPrescription({
      ...prescription,
      type: exerciseType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseType]);
  const [simpleExerciseInfo, setSimpleExerciseInfo] = useState({
    type: 'simple',
    name: '',
    body_part: '',
    sets: '',
    reps: '',
    rest: '15s',
    instructions: '',
  });
  const [motionExerciseInfo, setMotionExerciseInfo] = useState({
    type: 'motion',
    name: '',
    body_part: '',
    sets: '',
    reps: '',
    rest: '15s',
    // instructions: '', /* Jhony lee said to remove the instructions field in motion exercise */
    exercise: '',
    screenshot: false,
  });
  const handleMotionExerciseInfo = (name, value) => {
    setMotionExerciseInfo({ ...motionExerciseInfo, [name]: value });
  };
  const handleSimpleExerciseInfo = (name, value) => {
    setSimpleExerciseInfo({ ...simpleExerciseInfo, [name]: value });
  };
  const handlePatientDataById = useCallback(async () => {
    await request('get', `/clinicians/getPatientDetail/${patientId}`)
      .then((res) => {
        setPatient(res?.data?.user);
        setBreadCrumb(`${res?.data?.user?.first_name} ${res?.data?.user?.last_name}`);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, [patientId]);

  const handleExercideInfoReset = () => {
    setSimpleExerciseInfo({
      type: 'simple',
      name: '',
      body_part: '',
      sets: '',
      reps: '',
      rest: '15s',
      instructions: '',
    });
    setMotionExerciseInfo({
      type: 'motion',
      name: '',
      body_part: '',
      sets: '',
      reps: '',
      rest: '15s',
      instructions: '',
      exercise: '',
      screenshot: false,
    });
  };

  const handleAddToList = () => {
    if (exerciseType === 'simple') {
      if (
        simpleExerciseInfo.body_part &&
        simpleExerciseInfo.name &&
        simpleExerciseInfo.reps &&
        simpleExerciseInfo.rest &&
        simpleExerciseInfo.sets &&
        simpleExerciseInfo.type
      ) {
        setPrescription({
          ...prescription,
          exercises: [...prescription.exercises, simpleExerciseInfo],
        });

        handleExercideInfoReset();
      }
    } else if (exerciseType === 'motion') {
      if (
        motionExerciseInfo.body_part &&
        motionExerciseInfo.name &&
        motionExerciseInfo.reps &&
        motionExerciseInfo.rest &&
        motionExerciseInfo.sets &&
        motionExerciseInfo.exercise &&
        motionExerciseInfo.type
      ) {
        setPrescription({
          ...prescription,
          exercises: [...prescription.exercises, motionExerciseInfo],
        });
        handleExercideInfoReset();
      }
    }
  };

  const handlePrescriptionDetails = (name, value) => {
    setPrescription({ ...prescription, [name]: value });
  };

  const dateChange = (e) => {
    if (patient?.expiry_date) {
      if (moment(e).isAfter(moment(patient?.expiry_date))) {
        setOpenExtendDate(true);
        setSelectedStartDate(e);
      }
    } else {
      setPrescription({
        ...prescription,
        start_date: e,
      });
    }
  };

  const handlePrescriptionDetailsReset = () => {
    setPrescription({
      name: '',
      start_date: '',
      duration: 0,
      frequency: 0,
      clinician: clinicianId,
      patient: patientId,
      exercises: [],
    });
  };

  const handleGeneratePrescription = async () => {
    setDisableBtn(true);
    if (
      prescription?.clinician &&
      prescription?.duration &&
      prescription?.exercises &&
      prescription?.frequency &&
      prescription?.name &&
      prescription?.patient &&
      prescription?.start_date
    ) {
      // replacing the exercise objects with thier ID's on demand of Sir Bilawal / Ahmed / Zunaira
      const prescriptionBody =
        exerciseType === 'motion' ? JSON.parse(JSON.stringify(prescription)) : prescription;

      if (exerciseType === 'motion') {
        prescriptionBody.exercises.forEach((item, index) => {
          prescriptionBody.exercises[index].exercise = item.exercise.id;
        });
      }

      setLoading(true);
      await request('post', '/clinicians/prescriptions/createPrescription', prescriptionBody)
        .then(() => {
          setOpenGeneratePrescription(true);
          handlePrescriptionDetailsReset();
          setDisableBtn(false);
          setLoading(false);
          toast.success('Prescription Added Successfully');
        })
        .catch((error) => {
          setDisableBtn(false);
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    } else {
      setDisableBtn(false);
      setLoading(false);
      toast.error('Please fill all the fields');
    }
  };

  const handleGetAllExercise = async () => {
    await request('get', '/exercises')
      .then((res) => {
        setAllMotionExercise(res?.data?.exercises);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const validatingGeneratePrescriptionBtn =
    prescription &&
    prescription.name &&
    prescription.start_date &&
    prescription.duration &&
    prescription.frequency &&
    prescription.clinician &&
    prescription.patient &&
    prescription.exercises &&
    prescription.exercises.length > 0;

  const validatingGeneratePrescription = validatingGeneratePrescriptionBtn
    ? 'generatePrescriptionBtn'
    : 'generatePrescriptionBtn_diaabled';

  const goBackToProfile = () => navigate(`/patients/${patientId}/prescription`);

  const GeneratePrescriptionSection = openGeneratePrescription && (
    <GeneratePrescription
      goBackToProfile={goBackToProfile}
      openGeneratePrescription={openGeneratePrescription}
      setOpenGeneratePrescription={setOpenGeneratePrescription}
    />
  );

  const EditPatientPopup = openEditPatientModal && (
    <EditPatient
      patientDetails={patient}
      getPatients={handlePatientDataById}
      openEditPatient={openEditPatientModal}
      setOpenEditPatient={setOpenEditPatientModal}
    />
  );
  const ExpiryDateExtendPopup = openExtendDate && (
    <ExpiryDateExtend
      patientId={patientId}
      open={openExtendDate}
      setOpen={setOpenExtendDate}
      prescription={prescription}
      setPrescription={setPrescription}
      selectedStartDate={selectedStartDate}
    />
  );

  // sending inactivity object
  const handleInactiveEvent = () => {
    // setting boolean true to emit start object again
    isActive = true;
    if (patientIdArr?.length) {
      socket.emit('activity:stop', {
        clinician_id: clinicianId,
        patient_ids: patientIdArr?.map((item) => item),
        page_name: location?.pathname,
        start_time: Date.now(),
      });
    }
  };
  // resetting timeout on each time eventcalls
  const resetTimeout = () => {
    clearTimeout(window.timeout);
    window.timeout = setTimeout(handleInactiveEvent, timeoutDuration);
  };
  // sending start activity object
  const handleEvent = () => {
    // boolean ensures to emit the object first time function is called
    // array is necessary to check so object doesn't go empty
    if (isActive && patientIdArr?.length) {
      isActive = false;
      if (clinicianId && socket) {
        socket.emit('activity:start', {
          clinician_id: clinicianId,
          patient_ids: patientIdArr?.map((item) => item),
          page_name: location?.pathname,
          start_time: Date.now(),
        });
      }
      // timeout starts as the user lands
      resetTimeout();
    } else {
      // after initialization only timeout is called
      resetTimeout();
    }
  };

  // first time emit start event
  useEffect(() => {
    // array is necessary to check so object doesn't go empty
    if (patientIdArr?.length && (user.role === 'clinician' || user.role === 'clinic')) {
      handleEvent();
      eventsToTrack.forEach((eventName) => {
        window.addEventListener(eventName, handleEvent);
      });
    }

    return () => {
      eventsToTrack.forEach((eventName) => {
        window.removeEventListener(eventName, handleEvent);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientIdArr?.length]);

  useEffect(() => {
    handleGetAllExercise();
  }, []);

  useEffect(() => {
    handlePatientDataById();
  }, [handlePatientDataById]);

  return (
    <>
      <div className="gridish">
        <div className="sideBar_div">
          <Sidebar
            patientId={patientId}
            patientData={patient}
            selectedClinician={clinicianId}
            openEditpatientModal={openEditPatientModal}
            setOpenEditpatientModal={setOpenEditPatientModal}
          />
        </div>
        <div className="bgPrescription">
          <Breadcrumbs ClinicName={breadcrumb} />
          <div className="main_div_addPrescription">
            <div className="bgAddPrescription">
              <AddPrescription
                loading={loading}
                patientId={patientId}
                disableBtn={disableBtn}
                dateChange={dateChange}
                exerciseType={exerciseType}
                prescription={prescription}
                goBackToProfile={goBackToProfile}
                setExerciseType={setExerciseType}
                setPrescription={setPrescription}
                handleAddToList={handleAddToList}
                allMotionExercise={allMotionExercise}
                simpleExerciseInfo={simpleExerciseInfo}
                motionExerciseInfo={motionExerciseInfo}
                setMotionExerciseInfo={setMotionExerciseInfo}
                handleSimpleExerciseInfo={handleSimpleExerciseInfo}
                handleMotionExerciseInfo={handleMotionExerciseInfo}
                handlePrescriptionDetails={handlePrescriptionDetails}
                handleGeneratePrescription={handleGeneratePrescription}
                validatingGeneratePrescription={validatingGeneratePrescription}
              />
            </div>
          </div>
        </div>
        {GeneratePrescriptionSection}
      </div>
      {EditPatientPopup}
      {ExpiryDateExtendPopup}
    </>
  );
}
